import React from "react";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import moment from "moment";
import styled from "styled-components";

import { DATA_TYPE } from "components/table/types";

import "moment-timezone";

export const initTimeLocale = async (locale) => {
  await require(`dayjs/locale/${locale}.js`);
  const duration = require("dayjs/plugin/duration");
  const weekday = require("dayjs/plugin/weekday");
  const localeData = require("dayjs/plugin/localeData");
  const relativeTime = require("dayjs/plugin/relativeTime");
  const customParseFormat = require("dayjs/plugin/customParseFormat");
  const advancedFormat = require("dayjs/plugin/advancedFormat");
  const dayjsParser = require("dayjs-parser/dayjs");
  const tz = require("dayjs/plugin/timezone");
  const utc = require("dayjs/plugin/utc");
  const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

  dayjs.locale(locale);
  await dayjs.extend(customParseFormat);
  await dayjs.extend(advancedFormat);
  await dayjs.extend(localizedFormat);
  await dayjs.extend(duration);
  await dayjs.extend(weekday);
  await dayjs.extend(localeData);
  await dayjs.extend(relativeTime);
  await dayjs.extend(dayjsParser);
  await dayjs.extend(utc);
  await dayjs.extend(tz);
  await dayjs.extend(isSameOrBefore);
};

const possibleFormats = [
  "MM-D-YYYY HH:mm",
  "M/D/YYYY HH:mm",
  "M.D.YYYY HH:mm",
  "YYYY-MM-DD HH:mm:ss",
  "YYYY-MM-DD HH:mm",
  "YYYY/MM/DD HH:mm",
  "YYYY-M-D HH:mm:ss",
  "YY-M-D HH:mm:ss",
  "YYYY-M-D HH:mm",
  "M/D/YYYY HH:mm:ss",
  "M/D/YY HH:mm",
  "D/M/YY HH:mm:ss",
  "D/M/YYYY HH:mm:ss",
  "D/M/YYYY HH:mm",
  "DD.MM.YYYY HH:mm:ss",
  "DD.MM.YYYY HH:mm",
  "DD/MM/YYYY HH:mm:ss",
  "DD/MM/YYYY HH:mm",
  "YYYY/M/D HH:mm:ss",
  "YYYY/M/D HH:mm",
  "YYYY-MM-DD HH:mm:ss",
  "YYYY-MM-DD HH:mm",
  "YYYYMMDDTHH:mmzzz",
];

export const parseDateWithFormats = (date = null, format = null) => {
  if (!date) return dayjs();

  possibleFormats.unshift(format);

  return dayjs(date, possibleFormats);
};

export const parseDateWithoutTimeZones = (date = null) => {
  if (!date) return dayjs();
  // @ts-ignore
  return dayjs.utc(date);
};

export const parseDateWithTimeZone = (date = null, tz = null) => {
  if (tz) {
    // @ts-ignore
    return dayjs.tz(date, tz);
  }
  // @ts-ignore
  return dayjs.tz(date);
};

export const convertLocalDateToAnotherTimeZone = (date = null, tz) => {
  if (tz) {
    return moment(date).tz(tz, true);
  }

  return null;
};

export const parseDate = (date = null) => {
  if (!date) return dayjs();
  return dayjs(date);
};

export const formatDateTime = (date = null, format = "L LT") => {
  if (!date) return null;
  return parseDate(date).format(format);
};

export const formatDate = (date = null, format = "L") => {
  if (!date) return null;
  return parseDate(date).format(format);
};

export const formatTime = (date = null, format = "LT") => {
  if (!date) return null;
  return parseDate(date).format(format);
};

export const formatDuration = (duration = null, unit = "seconds", humanize = true, format = false) => {
  if (!duration) return null;

  if (format) {
    // @ts-ignore
    return dayjs.duration(duration, unit).format("HH:mm:ss");
  }
  // @ts-ignore
  return dayjs.duration(duration, unit).humanize(humanize);
};

export const formatDurationString = (duration, formatAsMinutes = false) => {
  if (!duration) return "00:00";

  const d = duration.split(":");
  if (duration.length > 2) {
    if (formatAsMinutes) {
      return `${d[0] && d[0]}:${d[1] && d[1]}`;
    }
    return `${d[0] && d[0]}:${d[1] && d[1]}:${Math.round(d[2] && d[2])}`;
  }
};

export const parseDurationString = (duration) => {
  if (!duration) return null;

  const d = duration.split(":");
  return `${d[0] && d[0]}:${d[1] && d[1]}:${d[2] ? Math.round(d[2] && d[2]) : "00"}`;
};

export const isDate = (columns, key) => {
  return [DATA_TYPE.DATETIME, DATA_TYPE.DATE, DATA_TYPE.TIME].includes(columns.find((col) => col.key === key)?.type);
};

export const relativeTime = (date: number) => {
  if (!date) return null;

  const t = new Date();
  t.setSeconds(t.getSeconds() + date);

  // @ts-ignore
  return dayjs(t).fromNow(true);
};

export const parseDurationAsSeconds = (val: string | undefined) => {
  if (!val) {
    return 0;
  }
  let seconds = 0;

  const timeBlock = val.split(":");
  const hours = timeBlock[0];
  const minutes = timeBlock[1];
  const sec = timeBlock[2];

  if (hours) {
    seconds += parseInt(hours) * 3600;
  }
  if (minutes) {
    seconds += parseInt(minutes) * 60;
  }
  if (sec) {
    seconds += parseInt(sec) * 1;
  }

  return seconds;
};

const StyledTable = styled.table`
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 1px;
  border-style: solid;
  color: #000000;

  td,
  th {
    border-width: 1px;
    border-color: #d9d9d9;
    border-style: solid;
    padding: 5px;
  }

  thead {
    background-color: #fafafa;
  }
`;

export const dateFormatTable = (
  <div>
    <StyledTable>
      <thead>
        <tr>
          <th>Input</th>
          <th>Example</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <code>YY</code>
          </td>
          <td>01</td>
          <td>Two-digit year</td>
        </tr>
        <tr>
          <td>
            <code>YYYY</code>
          </td>
          <td>2001</td>
          <td>Four-digit year</td>
        </tr>
        <tr>
          <td>
            <code>M</code>
          </td>
          <td>1-12</td>
          <td>Month, beginning at 1</td>
        </tr>
        <tr>
          <td>
            <code>MM</code>
          </td>
          <td>01-12</td>
          <td>Month, 2-digits</td>
        </tr>
        <tr>
          <td>
            <code>MMM</code>
          </td>
          <td>Jan-Dec</td>
          <td>The abbreviated month name</td>
        </tr>
        <tr>
          <td>
            <code>MMMM</code>
          </td>
          <td>January-December</td>
          <td>The full month name</td>
        </tr>
        <tr>
          <td>
            <code>D</code>
          </td>
          <td>1-31</td>
          <td>Day of month</td>
        </tr>
        <tr>
          <td>
            <code>DD</code>
          </td>
          <td>01-31</td>
          <td>Day of month, 2-digits</td>
        </tr>
        <tr>
          <td>
            <code>H</code>
          </td>
          <td>0-23</td>
          <td>Hours</td>
        </tr>
        <tr>
          <td>
            <code>HH</code>
          </td>
          <td>00-23</td>
          <td>Hours, 2-digits</td>
        </tr>
        <tr>
          <td>
            <code>h</code>
          </td>
          <td>1-12</td>
          <td>Hours, 12-hour clock</td>
        </tr>
        <tr>
          <td>
            <code>hh</code>
          </td>
          <td>01-12</td>
          <td>Hours, 12-hour clock, 2-digits</td>
        </tr>
        <tr>
          <td>
            <code>m</code>
          </td>
          <td>0-59</td>
          <td>Minutes</td>
        </tr>
        <tr>
          <td>
            <code>mm</code>
          </td>
          <td>00-59</td>
          <td>Minutes, 2-digits</td>
        </tr>
        <tr>
          <td>
            <code>s</code>
          </td>
          <td>0-59</td>
          <td>Seconds</td>
        </tr>
        <tr>
          <td>
            <code>ss</code>
          </td>
          <td>00-59</td>
          <td>Seconds, 2-digits</td>
        </tr>
        <tr>
          <td>
            <code>S</code>
          </td>
          <td>0-9</td>
          <td>Hundreds of milliseconds, 1-digit</td>
        </tr>
        <tr>
          <td>
            <code>SS</code>
          </td>
          <td>00-99</td>
          <td>Tens of milliseconds, 2-digits</td>
        </tr>
        <tr>
          <td>
            <code>SSS</code>
          </td>
          <td>000-999</td>
          <td>Milliseconds, 3-digits</td>
        </tr>
        <tr>
          <td>
            <code>Z</code>
          </td>
          <td>-05:00</td>
          <td>Offset from UTC</td>
        </tr>
        <tr>
          <td>
            <code>ZZ</code>
          </td>
          <td>-0500</td>
          <td>Compact offset from UTC, 2-digits</td>
        </tr>
        <tr>
          <td>
            <code>A</code>
          </td>
          <td>AM PM</td>
          <td>Post or ante meridiem, upper-case</td>
        </tr>
        <tr>
          <td>
            <code>a</code>
          </td>
          <td>am pm</td>
          <td>Post or ante meridiem, lower-case</td>
        </tr>
        <tr>
          <td>
            <code>Do</code>
          </td>
          <td>1st... 31st</td>
          <td>Day of Month with ordinal</td>
        </tr>
        <tr>
          <td>
            <code>X</code>
          </td>
          <td>1410715640.579</td>
          <td>Unix timestamp</td>
        </tr>
        <tr>
          <td>
            <code>x</code>
          </td>
          <td>1410715640579</td>
          <td>Unix ms timestamp</td>
        </tr>
      </tbody>
    </StyledTable>
  </div>
);
