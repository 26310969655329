import * as React from "react";

const AcceptedIcon = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill="#005EE6" />
      <g clipPath="url(#clip0_943_15227)">
        <path
          d="M14.1037 15.5998C12.591 15.5998 11.1402 16.1898 10.0706 17.24C9.00093 18.2902 8.4 19.7146 8.4 21.1998H14.1037M17.363 9.9998C17.363 10.8485 17.0196 11.6624 16.4083 12.2625C15.7971 12.8627 14.9681 13.1998 14.1037 13.1998C13.2393 13.1998 12.4103 12.8627 11.7991 12.2625C11.1878 11.6624 10.8444 10.8485 10.8444 9.9998C10.8444 9.15111 11.1878 8.33718 11.7991 7.73706C12.4103 7.13695 13.2393 6.7998 14.1037 6.7998C14.9681 6.7998 15.7971 7.13695 16.4083 7.73706C17.0196 8.33718 17.363 9.15111 17.363 9.9998Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.8881 19.0308C16.5736 18.7612 16.1001 18.7976 15.8306 19.1121C15.561 19.4266 15.5974 19.9001 15.9119 20.1696L16.8881 19.0308ZM18.08 21.0402L17.5919 21.6096L18.1765 22.1107L18.6621 21.5131L18.08 21.0402ZM21.7821 17.6731C22.0433 17.3517 21.9944 16.8793 21.6729 16.6181C21.3515 16.3569 20.8791 16.4058 20.6179 16.7273L21.7821 17.6731ZM15.9119 20.1696L17.5919 21.6096L18.5681 20.4708L16.8881 19.0308L15.9119 20.1696ZM18.6621 21.5131L21.7821 17.6731L20.6179 16.7273L17.4979 20.5673L18.6621 21.5131Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_943_15227">
          <rect width="16" height="16" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AcceptedIcon;
