import * as React from "react";

const CompleteIcon = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill={props.fill} />
      <path
        d="M7.82143 14.7637C7.50693 14.4942 7.03346 14.5306 6.76389 14.8451C6.49432 15.1596 6.53074 15.633 6.84524 15.9026L7.82143 14.7637ZM12 19.3332L11.5119 19.9026L12.0965 20.4037L12.5821 19.8061L12 19.3332ZM21.2488 9.13945C21.51 8.81797 21.4611 8.34562 21.1396 8.08442C20.8181 7.82322 20.3458 7.87208 20.0846 8.19356L21.2488 9.13945ZM6.84524 15.9026L11.5119 19.9026L12.4881 18.7637L7.82143 14.7637L6.84524 15.9026ZM12.5821 19.8061L21.2488 9.13945L20.0846 8.19356L11.4179 18.8602L12.5821 19.8061Z"
        fill="white"
      />
    </svg>
  );
};

export default CompleteIcon;
