import { ArrowRightOutlined } from "@ant-design/icons";
import { Trans } from "@lingui/macro";
import { Alert, Button } from "antd";
import Link from "next/link";

const NotificationBar = ({ notifications }) => {
  return (
    <>
      {notifications.map((notification, i) => (
        <Alert
          key={`alert-${i}`}
          message={notification.message}
          banner
          action={
            notification.url && (
              <Link href={notification.url}>
                <a target={notification.open_in_new_window ? "_blank" : "_self"}>
                  <Button type="primary" size="small">
                    <Trans>Take action</Trans>
                    <ArrowRightOutlined />
                  </Button>
                </a>
              </Link>
            )
          }
          type={notification.level === "danger" ? "error" : notification.level}
          closable={!notification.persist}
        />
      ))}
    </>
  );
};

export default NotificationBar;
