import React from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Spin, Typography } from "antd";
import styled from "styled-components";
import { Trans } from "@lingui/macro";

const StyledSpinner = styled(Spin)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.53);
  z-index: 9999999;
`;

export const Spinner = () => {
  return (
    <StyledSpinner
      tip={
        <Typography.Text>
          <Trans>Loading</Trans>...
        </Typography.Text>
      }
      indicator={<LoadingOutlined style={{ color: "#000000D8" }} spin />}
    />
  );
};

const PageLoader = () => {
  return <Spinner />;
};

const ContainerSpinner = styled(Spin)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9999999;
`;

const Container = styled(Row)`
  height: 100%;
`;

export const LoadingContainer = () => {
  return (
    <Container justify={"center"} align={"middle"}>
      <Col style={{ width: 70 }}>
        <ContainerSpinner
          tip={
            <Typography.Text>
              <Trans>Loading</Trans>...
            </Typography.Text>
          }
          indicator={<LoadingOutlined style={{ color: "#000000D8" }} spin />}
        />
      </Col>
    </Container>
  );
};

export default PageLoader;
