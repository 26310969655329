import * as React from "react";

const ActiveIcon = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6666 10.667L7.33327 14.0003L10.6666 17.3337"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6669 12.4001L20.6669 15.6001"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33327 14.0005L16.6666 14.0005"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ECECEC" />
    </svg>
  );
};

export default ActiveIcon;
