import * as React from "react";

import styled from "styled-components";

const StyledSVG = styled.svg`
  margin-right: 2px;
`;

const CheckedTaskIcon = (props) => {
  return (
    <StyledSVG width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="10" height="10" rx="2" fill="#9A39FB" />
      <path
        d="M4.13054 7.74059L4.11956 7.75157L2 5.63201L2.89317 4.73884L4.13058 5.97625L7.10683 3L8 3.89317L4.14156 7.75161L4.13054 7.74059Z"
        fill="white"
      />
    </StyledSVG>
  );
};

export default CheckedTaskIcon;
