import * as React from "react";

const UpIcon = (props) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1_6)">
        <path d="M1 0.799812L5 4.3998L1 7.9998" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_1_6">
          <rect width="9" height="7" fill="white" transform="matrix(0 -1 1 0 0 9)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UpIcon;
