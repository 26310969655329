import { t } from "@lingui/macro";
import { countBy, isEmpty, keyBy, uniqBy } from "lodash";
import hash from "object-hash";

import { DEFAULT_MANAGED_TASKS_STATES, TasksGroups } from "components/dashboard/hooks/tasks";
import { defaultLatLng } from "components/map";
import { AccountRole, Route, Task, TASK_COMMAND_ACTIONS } from "types/type";
import { parseDate } from "utils/datetime-utils";
import { filterTasksWithNonExistingRoutes, getTaskStateFromCommand, mergeTasks } from "utils/tasks";
import { extractUuidFromUrl } from "utils/uuid-utils";

export interface LatLngLiteral {
  lat: number;
  lng: number;
}

const mergeGroupedTasks = (group1: TasksGroups[], group2: TasksGroups[]) => {
  return Object.values(
    [...group1, ...group2].reduce((result, taskGorup) => {
      if (result[taskGorup?.assignee?.id]) {
        result[taskGorup.assignee.id].tasks = uniqBy(
          [...result[taskGorup.assignee.id].tasks, ...taskGorup.tasks],
          "id"
        );
      } else {
        result[taskGorup.assignee.id] = taskGorup;
      }

      return result;
    }, {})
  ) as TasksGroups[];
};

const getTaskWorkersBounds = async (workers: AccountRole[], tasks) => {
  const res: { lat: number; lng: number }[] = [];

  if (workers) {
    await workers.forEach((worker: any) => {
      const loc = worker.last_location;
      if (loc) {
        res.push({ lat: loc?.location?.coordinates[1], lng: loc?.location?.coordinates[0] });
      }
    });

    if (tasks) {
      await tasks.map((grouped) => {
        grouped.map((task) => {
          const coordinates = task?.address?.location?.coordinates;
          if (coordinates) {
            res.push({ lat: coordinates[1], lng: coordinates[0] });
          }
        });
      });
    }

    return res;
  }
  return [defaultLatLng];
};

export const UNASSIGNED_ID = "unassigned";

const onGetWorkers = (assignees) => {
  const res = [];

  if (!assignees?.account_roles) {
    return [];
  }

  assignees.account_roles.forEach((roles) => {
    assignees.last_time_locations.map((loc) => {
      if (loc.url === roles.last_time_location) {
        roles["last_location"] = loc;
      }
    });
    res.push(roles);
  });
  return res;
};

const onGroupTasks = (
  isClient,
  tasks,
  isLoading,
  workers,
  routesByUser: { [key: string]: Route } = {},
  override: Task[] = [],
  isUnmanaged = false,
  isUserOnlyAWorker: boolean
) => {
  // apply overrides to task list this exists in order to use persisted local state
  const routesByURL = keyBy(Object.values(routesByUser).flat(), "url");
  override?.forEach((task) => {
    tasks[task.id] = Object.assign(tasks[task.id] || {}, task);
  });

  // group tasks by assignees
  const res = [];
  if (isLoading && !workers?.account_roles) {
    return res;
  }

  // convert tasks object to ordered array
  const tasksList: any = filterTasksWithNonExistingRoutes(isUserOnlyAWorker, Object.values(tasks), routesByURL);

  let sortedTasks = tasksList;

  if (!isUnmanaged) {
    sortedTasks = tasksList.sort((a, b) => {
      if (a?.position === b?.position) {
        return a.id.localeCompare(b.id);
      }
      return a?.position - b?.position;
    });
  }

  const grouped = {
    assignee: { id: UNASSIGNED_ID, display_name: t`UNASSIGNED` },
    tasks: sortedTasks.filter((task) => !task.assignee),
  };

  if (routesByUser["null"]) {
    grouped["routes"] = routesByUser["null"];
  }

  res.push(grouped);

  workers
    .sort((a, b) => b.is_on_duty - a.is_on_duty)
    .forEach((assignee) => {
      const tasks = [];
      const assigneeRoutes = routesByUser?.[assignee.user] || [];
      let tasksCount = undefined;
      let routesCount = undefined;

      sortedTasks.forEach((task) => {
        if (assignee.user === task.assignee) {
          tasks.push(task);
        }
      });

      // count tasks and routes
      if (!isUnmanaged) {
        // exclude un-manage tasks
        const managedTasks = [...tasks].filter((task) => DEFAULT_MANAGED_TASKS_STATES.includes(task.state));

        tasksCount = managedTasks.length;
        routesCount = countBy(managedTasks, "route");
      }

      res.push({ assignee, tasks, routes: assigneeRoutes, tasksCount, routesCount });
    });

  // TODO: DEPRECATE: create unassigned workers for un-identified tasks.
  if (isClient) {
    const unidentifiedTasks = {};
    const workersIds = workers.map((w) => w.user);

    sortedTasks.map((task) => {
      if (task.assignee && !workersIds.includes(task.assignee)) {
        if (unidentifiedTasks[task.assignee]) {
          unidentifiedTasks[task.assignee].push(task);
        } else {
          unidentifiedTasks[task.assignee] = [task];
        }
      }
    });

    Object.keys(unidentifiedTasks).map((keys, i) => {
      res.push({
        assignee: {
          account: "",
          activated_at: "",
          created_at: "",
          deleted_at: "",
          email: "",
          display_name: "Worker " + Number(i + 1),
          id: extractUuidFromUrl(keys),
          is_active: false,
          is_on_duty: false,
          last_time_location: "",
          signature_image: "",
          state: "inactive",
          updated_at: "",
          user: keys,
          url: keys,
        },
        tasks: unidentifiedTasks[keys],
        tasksCount: unidentifiedTasks[keys]?.length,
      });
    });
  }

  return res;
};

const onReorderAndAssignTasks = (defaultPos, isDroppedOnParent, destinationIndex, group, tasks, route) => {
  const time = parseDate().toISOString();
  let idx = destinationIndex;

  if (idx === 0 && isDroppedOnParent) {
    if (!defaultPos) {
      idx = group?.tasks?.length || 0;
    }
  }

  const updatedTasks = mergeTasks([...tasks], [...group.tasks], idx);
  const commands = [];

  updatedTasks.map((task) => {
    // create payload
    let payload = {
      action: TASK_COMMAND_ACTIONS.REASSIGN,
      task_data: { position: task.position, route },
      task: task.url,
      time: time,
    };

    if (!group?.assignee?.user) {
      if (!task.assignee) {
        payload.action = TASK_COMMAND_ACTIONS.UPDATE;
        payload["assignee"] = null;
      } else {
        payload.action = TASK_COMMAND_ACTIONS.ASSIGN;
      }
    }

    if (task.assignee && !group?.assignee?.user) {
      payload.action = TASK_COMMAND_ACTIONS.UNASSIGN;
    }

    payload["assignee"] = group.assignee.user;
    payload["external_id"] = hash(payload);

    if (group?.assignee?.user) {
      if (group?.assignee?.user !== task.assignee) {
        task.state = getTaskStateFromCommand(TASK_COMMAND_ACTIONS.ASSIGN);
      }
    } else if (task.assignee && !group?.assignee?.user) {
      task.assignee = null;
      task.state = getTaskStateFromCommand(TASK_COMMAND_ACTIONS.UNASSIGN);
    }

    task.route = route;

    if (!isEmpty(payload?.action)) {
      task.assignee = group?.assignee?.user;
      commands.push({ title: "Repositioning task", id: hash(payload), payload: payload, tasks: [task] });
    }
  });

  return commands;
};

const truncateText = (input: string, limit: number, hideEllipses: boolean = false) => {
  if (input && input.length > limit) {
    return input.substring(0, limit) + `${hideEllipses ? "" : "..."}`;
  }
  return input;
};

const keyboardMetaKey = (event) => {
  const isUsingWindows = navigator.userAgent.indexOf("Macintosh") >= 0;
  return isUsingWindows ? event.metaKey : event.ctrlKey; // considering windows and linux will use the control key.
};

const filterShowOnDutyTasks = (tasks, showOffDuty) => {
  return tasks.filter((g) => (!showOffDuty ? g.assignee.is_on_duty || g.assignee.id === UNASSIGNED_ID : true));
};

export {
  getTaskWorkersBounds,
  keyboardMetaKey,
  onGetWorkers,
  onGroupTasks,
  onReorderAndAssignTasks,
  truncateText,
  mergeGroupedTasks,
  filterShowOnDutyTasks,
};
