import validator from "validator";

export const parseUuid = (apiUrl, endpoint, value) => {
  const regexp = new RegExp(`${apiUrl}${endpoint}\/(.*?)\/`);

  const match = regexp.exec(value);

  return (match && match[1]) || null;
};

export const extractUuidFromUrl = (value = "") => {
  if (!value && typeof value !== "string") {
    return "";
  }

  const values = `${value}`?.split("/");

  return values.find((v) => validator.isUUID(v));
};
