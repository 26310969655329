import * as React from "react";

const NearIcon = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6669 12.4001L20.6669 15.6001"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 17.3335L16.6666 14.0002L13.3333 10.6668"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6666 14L7.33327 14"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ECECEC" />
    </svg>
  );
};

export default NearIcon;
