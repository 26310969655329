const NightIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.837 11.2758C7.20788 10.9503 5.10725 8.86049 4.82078 6.29151C4.55722 3.92814 5.78717 1.82004 7.70458 0.745659C8.07719 0.537048 7.92302 -0.0194696 7.49366 0.000525016C7.01303 0.0228524 6.52248 0.090168 6.02476 0.207803C2.70955 0.993926 0.241776 3.81884 0.0175263 7.13795C-0.28398 11.6064 3.34401 15.3214 7.86251 15.3214C11.3076 15.3214 14.2341 13.1617 15.2969 10.1548C15.4385 9.75392 14.9339 9.451 14.6204 9.74492C13.4089 10.8786 11.6956 11.5058 9.837 11.2758Z"
        fill="#EEF1F5"
      />
    </svg>
  );
};

export default NightIcon;
