import ReactGA from "react-ga";

const GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;

export const initGA = () => {
  console.log("GA init");
  if (GOOGLE_ANALYTICS) {
    ReactGA.initialize(GOOGLE_ANALYTICS);
  } else {
    console.error("cannot initialize google analytics, NEXT_PUBLIC_GOOGLE_ANALYTICS is missing in .env");
  }
};

export const logPageView = () => {
  console.log(`Logging pageview for ${window.location.pathname}`);
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export const logEvent = (category = "", action = "") => {
  console.log(`Logging event ${category}/${action}`);
  if (category && action) {
    ReactGA.event({ category, action });
  }
};

export const logException = (description = "", fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};
