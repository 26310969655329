import { useEffect, useState } from "react";

import { useRouter } from "next/router";

import { initGA, logPageView } from "utils/analytics-utils";
import { initSentry } from "utils/sentry";

const useInitApplication = () => {
  const router = useRouter();

  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    /** Setup sentry */
    try {
      initSentry();
    } catch (e) {
      console.error("cannot initialize sentry", e);
    }

    /** Setup Google Analytics */
    initGA();
    // `routeChangeComplete` won't run for the first page load unless the query string is
    // hydrated later on, so here we log a page view if this is the first render and
    // there's no query string
    if (!router.asPath.includes("?")) {
      logPageView();
    }
  }, [router.asPath]);

  useEffect(() => {
    // Listen for page changes after a navigation or when the query changes
    router.events.on("routeChangeComplete", logPageView);
    return () => {
      router.events.off("routeChangeComplete", logPageView);
    };
  }, [router.events]);

  // Route changes loading
  useEffect(() => {
    router.events.on("routeChangeStart", (url) => {
      console.log(`Loading: ${url}`);
      setPageLoading(true);
    });
    router.events.on("routeChangeComplete", () => setPageLoading(false));
    router.events.on("routeChangeError", () => setPageLoading(false));
  }, [router.events]);

  return pageLoading;
};

export default useInitApplication;
