import { isNull, isUndefined } from "lodash";

const setSearchParams = (url: URL, key: string, value: string | string[] | number | undefined | null | boolean) => {
  if (isUndefined(value) || isNull(value)) {
    return;
  }

  url.searchParams.set(key, String(value));
};

export { setSearchParams };
