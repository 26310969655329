import { useEffect, useMemo, useState } from "react";

import { isEmpty } from "lodash";
import useSWRInfinite from "swr/infinite";
import { useAppContext } from "contexts/app";
import { BareFetcher } from "swr";

const useFetchAll = <T>(_url: string | undefined, fetcher?: BareFetcher<any>) => {
  const [url, setURL] = useState("");
  const [records, setRecords] = useState<any>([]);
  const [done, setDone] = useState(false);

  const { httpClient } = useAppContext();

  const { data, error, mutate } = useSWRInfinite(
    () => url && httpClient.getKey(url),
    fetcher || httpClient.swrInfiniteFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateFirstPage: true,
    }
  );

  const memonizeData = useMemo(() => data, [data]);

  const cursor = memonizeData?.[0]?.cursors;
  const rows = memonizeData?.[0]?.data;

  useEffect(() => {
    if (isEmpty(url)) {
      setURL(_url);
    }
  }, [_url, url]);

  useEffect(() => {
    if (!isEmpty(url) && done) {
      return;
    }

    if (rows) {
      setRecords([...records.concat(...rows)]);

      if (cursor?.next) {
        setURL(cursor.next);
      } else {
        setDone(true);
      }
    }
  }, [rows]);

  const onAddRecords = (v: any) => {
    records.push(v);
    setRecords([...records]);
  };

  return { records: done ? (records as T) : undefined, error, loading: !done && !!url, mutate, onAddRecords };
};

export default useFetchAll;
