import { Trans } from "@lingui/macro";
import { Typography } from "antd";
import styled from "styled-components";

const MapFallbackError = () => {
  return (
    <Container>
      <div>
        <Typography.Text strong>
          <Trans> An exception occurred while rendering the map.</Trans>
        </Typography.Text>
        <div>
          If the issue remains please contact <a href="mailto:info@gsmtasks.com">info@gsmtasks.com</a> for support.
        </div>
      </div>
      <a className={"reload"} href={window.location.href}>
        Refresh Page
      </a>
    </Container>
  );
};

const Container = styled.div`
  .ant-typography {
    color: #fff !important;
  }

  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  top: 0;
  background-color: #2a2f39;
  padding: 16px;
  width: 100%;

  .reload {
    color: #fff !important;
    background: #9a39fb;
    padding: 10px;
    margin-left: 10px;
    border-radius: 3px;
  }
`;
export { MapFallbackError };
