import * as React from "react";

const PickUpIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16675 4.5V2.5H19.1667V4.5H2.16675ZM0.666748 2C0.666748 1.44772 1.11446 1 1.66675 1H19.6667C20.219 1 20.6667 1.44772 20.6667 2V5C20.6667 5.55228 20.219 6 19.6667 6H19.4167V17C19.4167 17.9665 18.6332 18.75 17.6667 18.75H3.66675C2.70025 18.75 1.91675 17.9665 1.91675 17V6H1.66675C1.11446 6 0.666748 5.55228 0.666748 5V2ZM3.41675 6V17C3.41675 17.1381 3.52868 17.25 3.66675 17.25H17.6667C17.8048 17.25 17.9167 17.1381 17.9167 17V6H3.41675ZM14.1971 11.5303C13.9042 11.8232 13.4293 11.8232 13.1364 11.5303L11.4166 9.81054L11.4166 15.1997C11.4166 15.6139 11.0808 15.9497 10.6666 15.9497C10.2524 15.9497 9.91662 15.6139 9.91662 15.1997L9.91663 9.81078L8.19708 11.5303C7.90418 11.8232 7.42931 11.8232 7.13642 11.5303C6.84352 11.2374 6.84352 10.7626 7.13642 10.4697L10.1364 7.46967C10.4293 7.17678 10.9042 7.17678 11.1971 7.46967L14.1971 10.4697C14.49 10.7626 14.49 11.2374 14.1971 11.5303Z"
        fill="#2A2F39"
      />
    </svg>
  );
};

export default PickUpIcon;
