import { useCallback, useRef, useState } from "react";

import { t } from "@lingui/macro";
import { message } from "antd";

import useInterval from "hooks/interval";
import { httpStatusCodes } from "utils/status-codes-utils";
import useDeepCompareEffect from "use-deep-compare-effect";

const onHandleRetries = (error, retryCount, setRetryCount, setRetryAfter) => {
  message.warning(t`An error occurred while fetching tasks, tasks states maybe inconsistent.`);

  // disable retries for 404 response
  if (error.status === httpStatusCodes.NOT_FOUND || error.status === httpStatusCodes.UNAUTHORIZED) return;

  // end after trying up to 10 times
  if (retryCount >= 10) return;

  // Incremental retires.
  setRetryCount(retryCount + 1);
  setRetryAfter(2 ** 1000 * retryCount);
};

const useTasksFetcher = (url: string | undefined, client: any, reloadInterval: number = 0) => {
  const [data, setData] = useState<any>(undefined);
  const [retryAfter, setRetryAfter] = useState<number>(200);
  const [retryCount, setRetryCount] = useState<number>(0);
  const prevURL = useRef(null);

  const fetcher = useCallback(() => {
    if (prevURL.current === url) {
      // prevent duplicate requests
      return;
    }

    prevURL.current = url;

    client(url).then((response) => {
      prevURL.current = null;
      if (response.status !== httpStatusCodes.OK) {
        onHandleRetries(response, retryCount, setRetryCount, setRetryAfter);
        return;
      }

      if (response) {
        setData({ ...response });
      } else {
        setData({});
      }
    });
  }, [client, retryCount, url]);

  // fetch initial requests and handle retires.
  useDeepCompareEffect(() => {
    if (!url) {
      return;
    }

    if (client && reloadInterval === 0) {
      fetcher();
    }

    // retries
    if (retryCount > 0 && retryAfter) {
      const loader = () => {
        return setTimeout(() => {
          fetcher();
        }, retryAfter);
      };

      return () => {
        clearTimeout(loader());
      };
    }
  }, [{ url, retryCount, retryAfter, reloadInterval }]);

  // used to polling updates
  useInterval(fetcher, reloadInterval === 0 ? null : reloadInterval);

  const reset = () => {
    setData(undefined);
  };

  return { data, reset };
};

export default useTasksFetcher;
