const FolderOpenIcon = ({ ...props }) => {
  return (
    <svg width="18" height="19" viewBox="0 0 25 25" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.62888 12.3578C1.40567 11.1301 2.34881 10 3.59662 10H20.8038C22.0516 10 22.9947 11.1301 22.7715 12.3578L21.4988 19.3578C21.3259 20.3088 20.4976 21 19.531 21H4.86935C3.90278 21 3.07451 20.3088 2.90161 19.3578L1.62888 12.3578Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1 5.8H12.3726L10.5726 4H3.9C3.66131 4 3.43239 4.09482 3.2636 4.2636C3.09482 4.43239 3 4.66131 3 4.9V8H21V6.7C21 6.46131 20.9052 6.23239 20.7364 6.0636C20.5676 5.89482 20.3387 5.8 20.1 5.8Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

export default FolderOpenIcon;
