import { chain } from "lodash";

const getCursors = (link) => {
  const links = chain(link)
    .split(",")
    .map((link) => {
      return {
        ref: link
          .split(";")[1]
          .replace(/rel="(.*)"/, "$1")
          .trim(),
        url: link
          .split(";")[0]
          .replace(/<(.*)>/, "$1")
          .trim(),
      };
    })
    .keyBy("ref")
    .mapValues("url")
    .value();

  return links;
};

export { getCursors };
