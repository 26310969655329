import * as React from "react";

const UnAssignedIcon = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill={props.fill} />
      <path
        d="M14.1037 15.5998C12.591 15.5998 11.1402 16.1898 10.0706 17.24C9.00093 18.2902 8.4 19.7146 8.4 21.1998H14.1037M17.363 9.9998C17.363 10.8485 17.0196 11.6624 16.4083 12.2625C15.7971 12.8627 14.9681 13.1998 14.1037 13.1998C13.2393 13.1998 12.4103 12.8627 11.7991 12.2625C11.1878 11.6624 10.8444 10.8485 10.8444 9.9998C10.8444 9.15111 11.1878 8.33718 11.7991 7.73706C12.4103 7.13695 13.2393 6.7998 14.1037 6.7998C14.9681 6.7998 15.7971 7.13695 16.4083 7.73706C17.0196 8.33718 17.363 9.15111 17.363 9.9998Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.2 17.2002L21.2 21.2002" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M21.2 17.2002L17.2 21.2002" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default UnAssignedIcon;
