import React, { RefObject, useEffect, useRef, useState } from "react";

import { Menu, MenuProps } from "antd";
import styled from "styled-components";
import useDeepCompareEffect from "use-deep-compare-effect";
import { cascadeMenuItems } from "components/menus/context-menu";

interface MapContextMenuProps {
  areaRef: RefObject<HTMLDivElement>;
  menuProps?: MenuProps;
  position: { x: number; y: number };
  items?: any;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MapContextMenu = ({ items, areaRef, menuProps, position, open, setOpen }: MapContextMenuProps) => {
  const [contextPos, setContextPos] = useState(null);

  const ref = useRef(null);

  useEffect(() => {
    if (position && position.y && position.y) {
      setOpen(true);
      setContextPos({ posX: position.x, posY: position.y });
    }
  }, [position, setOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  });
  const handleClickOutside = (e) => {
    // This is to prevent closing context menu when ok-button is clicked [menus/context-menu:L90]
    if (ref.current && !ref.current.contains(e.target) && e.target?.parentNode.id !== "ok-button") {
      if (typeof e.target?.className === "string") {
        if (e.target?.className?.startsWith("ant-popover")) {
          return;
        }
      }

      setOpen(false);
    }
  };

  // handle context menu position if menu is greater than containing area.
  useDeepCompareEffect(() => {
    if (areaRef.current && ref.current) {
      if (contextPos.posX + ref.current.firstElementChild.clientWidth > areaRef.current?.clientWidth) {
        setContextPos({ ...contextPos, posX: contextPos.posX - ref.current.firstElementChild.clientWidth });
      }
      if (contextPos.posY + ref.current.firstElementChild.clientHeight > areaRef.current?.offsetHeight) {
        setContextPos({ ...contextPos, posY: contextPos.posY - ref.current.firstElementChild.clientHeight });
      }
    }
  }, [areaRef, contextPos]);

  if (!open || !contextPos) {
    return <></>;
  }

  return (
    <div ref={ref} onContextMenu={(e) => e.preventDefault()}>
      <StyledContextMenu x={contextPos.posX} y={contextPos.posY}>
        {items ? cascadeMenuItems(items, null, setOpen, undefined, undefined) : <Menu {...menuProps} />}
      </StyledContextMenu>
    </div>
  );
};

const StyledContextMenu = styled(Menu)<{ x: number; y: number }>`
  position: absolute;
  top: ${(props) => props.y}px;
  width: 200px;
  left: ${(props) => props.x}px;
  z-index: 1;
`;

export default MapContextMenu;
