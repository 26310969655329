import { get, isEmpty } from "lodash";
import { useRouter } from "next/router";

const useRedirect = (shouldRedirect, to) => {
  const router = useRouter();

  if (!isEmpty(shouldRedirect)) {
    router.push(to);
  }
};

const useRedirectDeActiveAccounts = (configuration) => {
  const path = "/settings/subscriptions";
  const router = useRouter();
  const isDeActive = get(configuration, "account.state") === "deactive";

  if (isDeActive && router.pathname !== path) {
    router.push(`${path}`);
  }
};

export default useRedirect;
export { useRedirectDeActiveAccounts };
