import * as React from "react";

const CancelledIcon = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill={props.fill} />
      <path
        d="M8.53033 7.46967C8.23744 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76256 7.17678 8.23744 7.46967 8.53033L8.53033 7.46967ZM19.4697 20.5303C19.7626 20.8232 20.2374 20.8232 20.5303 20.5303C20.8232 20.2374 20.8232 19.7626 20.5303 19.4697L19.4697 20.5303ZM7.46967 19.4697C7.17678 19.7626 7.17678 20.2374 7.46967 20.5303C7.76256 20.8232 8.23744 20.8232 8.53033 20.5303L7.46967 19.4697ZM20.5303 8.53033C20.8232 8.23744 20.8232 7.76256 20.5303 7.46967C20.2374 7.17678 19.7626 7.17678 19.4697 7.46967L20.5303 8.53033ZM7.46967 8.53033L19.4697 20.5303L20.5303 19.4697L8.53033 7.46967L7.46967 8.53033ZM8.53033 20.5303L20.5303 8.53033L19.4697 7.46967L7.46967 19.4697L8.53033 20.5303Z"
        fill="white"
      />
    </svg>
  );
};

export default CancelledIcon;
