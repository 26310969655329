import React, { MutableRefObject, ReactElement, useState } from "react";

import { t } from "@lingui/macro";
import { Dropdown, Menu, Popconfirm } from "antd";
import styled from "styled-components";

const StyledPopConfirm = styled(Popconfirm)`
  padding: 8px 0;
`;

const MenuItem = styled(Menu.Item)`
  margin: 0 !important;
  padding: 0 16px !important;
  height: 34px !important;
  line-height: 34px !important;
  background: #ffffff !important;
  color: black !important;

  :hover {
    background: #ebebeb;
  }
`;

const SubMenu = styled(Menu.SubMenu)`
  .ant-menu-submenu-title {
    height: 34px !important;
    line-height: 34px !important;
  }

  :hover {
    background: #ebebeb;
  }
`;

export interface MenuProps {
  label: string;
  action?: (data: any) => void; // row data is passed to action
  cascade?: MenuProps[];
  confirm?: boolean;
  confirmMsg?: string;
  disabled?: boolean;
}

// ignoreClickOutside is used to prevent closing PopConfirm when onclickOutside event handler is called.
//TODO: remove ignoreClickOutside and related codes when antd is updated to 5.* use onPopupClick
export const cascadeMenuItems = (items, rowData, onChange, ignoreClickOutside, onClosePopover) => {
  return items.map((item, i) => {
    if (!item?.label) return;
    if (item.cascade) {
      return (
        <SubMenu title={item.label} key={item.label + i}>
          {item.cascade.map((data, i) => {
            return (
              <MenuItem
                color="black"
                key={item.label + i}
                disabled={item?.disabled}
                onClick={() => {
                  data.action(rowData);
                  onChange(false);
                }}
              >
                {data.label}
              </MenuItem>
            );
          })}
        </SubMenu>
      );
    }

    return (
      <StyledPopConfirm
        key={item.label + i}
        disabled={item.confirm !== true}
        title={item.confirmMsg || t`Do you want to proceed?`}
        onConfirm={(e) => {
          e.stopPropagation();

          item.action(rowData);
          onChange(false);
        }}
        onOpenChange={(e) => {
          if (ignoreClickOutside) {
            ignoreClickOutside.current = e;
            if (!e) {
              onClosePopover && onClosePopover();
            }
          }
        }}
        okText={t`Yes`}
        cancelText={t`No`}
        okButtonProps={{
          id: "ok-button",
        }}
      >
        <MenuItem
          key={item.label + i}
          disabled={item?.disabled}
          onClick={(e) => {
            if (item.confirm === true) {
              return;
            }

            e.domEvent.stopPropagation();

            item.action(rowData);
            onChange(false);
          }}
        >
          {item.label}
        </MenuItem>
      </StyledPopConfirm>
    );
  });
};

export const Menus = ({ items, rowData, onChange, ignoreClickOutside = undefined, onClosePopover = undefined }) => {
  if (!items) return null;
  return <Menu>{cascadeMenuItems(items, rowData, onChange, ignoreClickOutside, onClosePopover)}</Menu>;
};

interface ContextMenuProps {
  children: ReactElement<any, any>;
  items: MenuProps[];
  rowData: any; // any is allowed as row props varies depending on data returned from API. Can implement Generic types
  trigger?: "click" | "hover" | "contextMenu";
  forceDisplay?: boolean;
  ignoreClickOutside?: MutableRefObject<boolean>;
  onClosePopover?: () => void;
}

const ContextMenu = ({
  children,
  items,
  rowData,
  trigger = "contextMenu",
  forceDisplay,
  ignoreClickOutside,
  onClosePopover,
}: ContextMenuProps) => {
  const [show, setShow] = useState(false);

  return (
    <Dropdown
      open={show}
      overlay={
        <Menus
          items={items}
          rowData={rowData}
          onChange={setShow}
          ignoreClickOutside={ignoreClickOutside}
          onClosePopover={onClosePopover}
        />
      }
      trigger={[trigger]}
      onOpenChange={() => {
        if (forceDisplay === false) {
          return;
        }
        setShow(!show);
      }}
      destroyPopupOnHide={true}
    >
      {children}
    </Dropdown>
  );
};

export default ContextMenu;
