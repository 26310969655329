import { useEffect, useState } from "react";

import useDeepCompareEffect from "use-deep-compare-effect";

const Marker = ({
  label,
  onContextMenu = null,
  centerLabel = false,
  isSelected = false,
  placeId = "",
  children,
  onDragEnd,
  onClick,
  content = "",
  onDragStart = null,
  onSelect,
  onHover = null,
  ...props
}: any) => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [infowindow, setInfowindow] = useState<google.maps.InfoWindow>();

  useEffect(() => {
    if (!marker) {
      const marker = new google.maps.Marker();
      setMarker(marker);
      setInfowindow(
        new google.maps.InfoWindow({
          disableAutoPan: true,
        })
      );
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.addListener("click", (e) => {
        onSelect && onSelect(e);
        if (content) {
          infowindow.setContent(content);
          infowindow.open(props.map, marker);
          props.setInfoWindow && props.setInfoWindow(infowindow);
          return;
        }
        onClick && onClick(e);
      });

      return () => {
        google.maps.event.clearListeners(marker, "click");
      };
    }
  }, [content, infowindow, marker, onClick, onSelect, props, props.map]);

  useDeepCompareEffect(() => {
    if (isSelected && props.map) {
      props.closeInfoWindow && props.closeInfoWindow();
      if (content && infowindow) {
        infowindow.setContent(content);
        infowindow.open(props.map, marker);
        props.setInfoWindow && props.setInfoWindow(infowindow);
      }
    }
  }, [{ state: isSelected }]);

  useEffect(() => {
    if (marker) {
      onContextMenu && marker.addListener("rightclick", (data) => onContextMenu(data));
      onDragEnd && marker.addListener("dragend", (e) => onDragEnd(e));
      onDragStart && marker.addListener("dragstart", (e) => onDragStart(e));
      onHover && marker.addListener("mouseover", (e) => onHover(e));

      return () => {
        google.maps.event.clearListeners(marker, "rightclick");
        google.maps.event.clearListeners(marker, "dragend");
        google.maps.event.clearListeners(marker, "dragstart");
        google.maps.event.clearListeners(marker, "mouseover");
      };
    }
  }, [onContextMenu, marker, onDragEnd, onClick, onDragStart, onHover]);

  useEffect(() => {
    if (marker) {
      if (props.anchor) {
        props["icon"]["anchor"] = new google.maps.Point(
          props.anchor ? props.anchor[0] : 0,
          props.anchor ? props.anchor[1] : 0
        );
      }
      if (props.dimension) {
        props["icon"]["scaledSize"] = new google.maps.Size(
          props.dimension ? props.dimension[0] : 30,
          props.dimension ? props.dimension[1] : 30
        );
      }

      if (label) {
        props["label"] = {
          ...props,
          text: label,
          className: !centerLabel ? "marker" : "center-marker",
        };
      } else {
        props["label"] = undefined;
      }

      props["draggable"] = !!onDragEnd;
      marker.setOptions(props);
    }
  }, [marker, props, label, centerLabel, onDragEnd]);

  return null;
};

export default Marker;
