import * as React from "react";

const TransitIcon = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill={props.fill} />
      <path
        d="M10 12.743H18M21.2 12.7396V18.9144C21.2 19.4824 20.7395 19.943 20.1714 19.943H19.1429C18.5748 19.943 18.1143 19.4824 18.1143 18.9144V17.8858H9.88571V18.9144C9.88571 19.4824 9.42521 19.943 8.85714 19.943H7.82857C7.26051 19.943 6.8 19.4824 6.8 18.9144V12.7396L8.98671 9.09746C9.54447 8.16846 10.5486 7.6001 11.6322 7.6001H16.3678C17.4514 7.6001 18.4555 8.16846 19.0133 9.09746L21.2 12.7396Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TransitIcon;
