import useAddressAutoCompleteRealTimeService, { ServiceBackends } from "hooks/realtime-service";
import { getPlaceDetails, setLatLngPrecision } from "components/map/utils";
import { useDebounce } from "hooks/debounce";
import { first, get } from "lodash";
import { useAppContext } from "contexts/app";
import { useGooglePlacesAPI } from "components/map/autocomplete-service/google";
import { reverseCoordinates } from "utils/tasks";

interface FeatureProperties {
  backend: ServiceBackends;
  formatted_address: string;
  postal_code: string;
  country: string;
  state: string;
  city: string;
  street: string;
  house_number: string;
}

interface Feature {
  type: "Feature";
  geometry: {
    type: "Point";
    coordinates: [number, number];
  };
  properties: FeatureProperties;
  placeId?: string;
}

const useAutoCompleteService = (params) => {
  const { configuration } = useAppContext();

  const provider = get(configuration, "features.address_autosuggest_provider", "");
  const isProviderGoogle = provider.includes("google");

  const debouncedAddress = useDebounce(params, 200);
  const { data, loading: realTimeLoading } = useAddressAutoCompleteRealTimeService({
    address: !isProviderGoogle && debouncedAddress,
  });
  const { suggestions: googleSuggestion, loading: googlePlacesLoading } = useGooglePlacesAPI(
    isProviderGoogle && params
  );

  const suggestions = isProviderGoogle ? googleSuggestion : data?.features;
  let isLoading = realTimeLoading || googlePlacesLoading;

  const setPlaceCallback = async (options, props, onChange, setAddress, reverse) => {
    let place = options?.places;
    const placeId = options.places?.placeId;
    let formattedAddress = place?.properties?.formatted_address;
    let location = place?.geometry?.coordinates;

    // google places prediction does not return coordinates and formatted address so getPlaceDetails is used to retrieve this information
    if (placeId) {
      const places = await getPlaceDetails(placeId);
      const placeDetails = first(places.results);

      formattedAddress = placeDetails.formatted_address;
      location = [placeDetails.geometry.location.lng(), placeDetails.geometry.location.lat()];
    }

    onChange({
      raw_address: formattedAddress,
      formatted_address: formattedAddress,
      location: {
        type: "Point",
        coordinates: reverse ? reverseCoordinates(location) : location,
      },
    });
    if (props?.shouldClearInput) {
      setAddress("");
    } else {
      setAddress(formattedAddress);
    }
  };

  const setLocationCallback = async (value, setCenter, setPlace) => {
    let lat = value?.geometry?.coordinates?.[1];
    let lng = value?.geometry?.coordinates?.[0];
    const placeId = value?.placeId;

    if (placeId) {
      const places = await getPlaceDetails(placeId);
      const placeDetails = first(places.results);

      lng = placeDetails?.geometry?.location?.lng();
      lat = placeDetails?.geometry?.location?.lat();
    }

    if (lat === undefined && lng === undefined) {
      return;
    }

    const coordinates = {
      lat: lat,
      lng: lng,
    };

    setCenter(setLatLngPrecision(coordinates));
    setPlace(coordinates);
  };

  return { isLoading, suggestions, setPlaceCallback, setLocationCallback };
};

export { useAutoCompleteService };
