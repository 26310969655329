import * as React from "react";

const DropOffIcon = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.83325 3.5V1.5H18.8333V3.5H1.83325ZM0.333252 1C0.333252 0.447715 0.780967 0 1.33325 0H19.3333C19.8855 0 20.3333 0.447715 20.3333 1V4C20.3333 4.55228 19.8855 5 19.3333 5H19.0833V16C19.0833 16.9665 18.2998 17.75 17.3333 17.75H3.33325C2.36675 17.75 1.58325 16.9665 1.58325 16V5H1.33325C0.780967 5 0.333252 4.55228 0.333252 4V1ZM3.08325 5V16C3.08325 16.1381 3.19518 16.25 3.33325 16.25H17.3333C17.4713 16.25 17.5833 16.1381 17.5833 16V5H3.08325ZM6.80292 10.6694C7.09582 10.3765 7.57069 10.3765 7.86358 10.6694L9.58337 12.3892L9.58338 7C9.58338 6.58579 9.91916 6.25 10.3334 6.25C10.7476 6.25 11.0834 6.58579 11.0834 7L11.0834 12.3889L12.8029 10.6694C13.0958 10.3765 13.5707 10.3765 13.8636 10.6694C14.1565 10.9623 14.1565 11.4371 13.8636 11.73L10.8636 14.73C10.5707 15.0229 10.0958 15.0229 9.80292 14.73L6.80292 11.73C6.51003 11.4371 6.51003 10.9623 6.80292 10.6694Z"
        fill="#2A2F39"
      />
    </svg>
  );
};

export default DropOffIcon;
