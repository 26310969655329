import * as React from "react";

import styled from "styled-components";

const StyledSVG = styled.svg`
  margin-right: 2px;
`;

const UnCheckedTaskIcon = (props) => {
  return (
    <StyledSVG width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.5" width="9" height="9" rx="1.5" stroke="#949494" />
    </StyledSVG>
  );
};

export default UnCheckedTaskIcon;
