import { useEffect, useRef } from "react";

export const useUnload = (fn, active: boolean = false) => {
  // TODO support navigation as well
  const callback = useRef(fn);

  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => callback.current?.(...args);

    if (active) {
      window.addEventListener("beforeunload", onUnload);
    }
    return () => window.removeEventListener("beforeunload", onUnload);
  }, [active]);
};
