import { useCallback, useState } from "react";

import { filter, isArray, isEmpty } from "lodash";

import {
  DEFAULT_MANAGED_TASKS_STATES,
  DEFAULT_UNMANAGED_TASKS_STATES,
  TASK_STATE,
  TasksGroups,
} from "components/dashboard/hooks/tasks";
import { UNASSIGNED_ID } from "components/dashboard/utils";
import { parseDate } from "utils/datetime-utils";
import { extractUuidFromUrl } from "utils/uuid-utils";

const useStoreFilters = (
  filterKey,
  resetRemoteFilters,
  onApplyRemoteFilters,
  tasksState,
  setTasksState,
  tasks: TasksGroups[]
) => {
  const [loading, setLoading] = useState(false);
  const [shouldTriggerAPI, setShouldTriggerAPI] = useState(false);

  const [filters, setFilters] = useState({
    active: {
      states: [...DEFAULT_MANAGED_TASKS_STATES],
      datetime: [],
      metaFields: {
        key: "",
        value: "",
        title: "",
      },
      assignees: [],
    },
  });

  const resetFilter = () => {
    if (tasksState === TASK_STATE.UNMANAGED) {
      setTasksState(TASK_STATE.MANAGED);
      resetRemoteFilters();
    }
  };

  const onApply = (action) => {
    setLoading(true);
    switch (action.type) {
      case "FILTER_BY_STATES":
        if (DEFAULT_UNMANAGED_TASKS_STATES.includes(action?.states[0]) && filters.active["datetime"].length === 2) {
          setShouldTriggerAPI(true);
        } else {
          resetFilter();
        }

        filters.active.states = action.states;
        setFilters({ ...filters });

        break;
      case "FILTER_BY_DATE":
        if (DEFAULT_UNMANAGED_TASKS_STATES.includes(filters?.active?.states[0]) && action?.datetime?.length === 2) {
          setShouldTriggerAPI(true);
        } else {
          resetFilter();
        }

        // in localstorage we store datetime as string, which creates the need for parsing the dates.
        if (action.datetime && action.datetime.length > 0) {
          if (typeof action.datetime[0] === "string") {
            action.datetime.map((d, i) => {
              action.datetime[i] = parseDate(d);
            });
          }
        }

        filters.active.datetime = action.datetime;
        setFilters({ ...filters });

        break;
      case "FILTER_BY_METAFIELDS":
        filters.active["metaFields"] = action.metaFields;
        setFilters({ ...filters });

        break;
      case "FILTER_BY_ASSIGNEES":
        filters.active["assignees"] = action.assignees;
        setFilters({ ...filters });

        break;
      default:
        console.error("error: invalid filter type specified", action.type);
    }

    if (!isEmpty(filters?.active)) {
      localStorage.setItem(filterKey, JSON.stringify(filters.active)); // Persist filters params to local storage
    }
    setLoading(false);
  };

  // Apply filters on tasks...
  const tasksResults = useCallback(
    (tasks) => {
      let results = tasks;
      if (shouldTriggerAPI && filters.active["datetime"].length === 2) {
        const idx = filters.active["states"][0];
        let dt;

        if (idx === "cancelled") {
          dt = [
            { key: "cancelled_at", condition: "__gte", value: filters.active["datetime"][0].format() },
            { key: "cancelled_at", condition: "__lt", value: filters.active["datetime"][1].format() },
            { key: "ordering", condition: "", value: "cancelled_at" },
          ];
        } else {
          dt = [
            { key: "completed_at", condition: "__gte", value: filters.active["datetime"][0].format() },
            { key: "completed_at", condition: "__lt", value: filters.active["datetime"][1].format() },
            { key: "ordering", condition: "", value: "completed_at" },
          ];
        }
        onApplyRemoteFilters([{ ...(idx && { key: "state", condition: "", value: idx }) }, ...dt]);
        setShouldTriggerAPI(false);
        setTasksState(TASK_STATE.UNMANAGED);
        return results;
      }

      Object.keys(filters.active).map((key) => {
        switch (key) {
          case "states":
            // trigger api filters when un-managed states is detected and date is set
            if (!isEmpty(filters.active[key])) {
              results = results.map((group) => {
                const filtered = [];

                group.tasks.map((task) => {
                  if (filters.active[key] && filters.active[key].includes(task.state)) {
                    filtered.push(task);
                  }
                });
                group.tasks = filtered;
                return group;
              });
            }

            break;
          case "datetime":
            if (DEFAULT_UNMANAGED_TASKS_STATES.includes(filters?.active?.states[0])) {
              return tasks;
            }
            if (!isEmpty(filters.active["datetime"])) {
              tasks.forEach((group) => {
                const filtered = [];

                group.tasks.forEach((task) => {
                  switch (true) {
                    case filters.active[key].length === 2:
                      // Handle bounds
                      if (
                        filters.active[key][1] &&
                        filters.active[key][1].isAfter(task.complete_after) &&
                        ((filters.active[key][0] && filters.active[key][0].isBefore(task.complete_before)) ||
                          task.complete_before == undefined)
                      ) {
                        filtered.push(task);
                      }
                      break;
                    default:
                      // Handles complete before
                      if (filters.active[key][0] && filters.active[key][0].isBefore(task.complete_before)) {
                        filtered.push(task);
                      }

                      // Handles complete after
                      if (filters.active[key][1] && filters.active[key][1].isAfter(task.complete_after)) {
                        filtered.push(task);
                      }
                  }
                });
                group.tasks = filtered;
                return group;
              });
            }

            break;
          case "metaFields":
            if (!isEmpty(filters.active["metaFields"].value) && !isEmpty(filters.active["metaFields"].key)) {
              results = tasks.map((group) => {
                const filtered = [];

                group.tasks.map((task) => {
                  const filterKey = filters?.active?.[key]?.key;
                  const filterValue = filters?.active?.[key]?.value;
                  const taskMetaFields = task?.metafields;
                  const metaField = taskMetaFields?.[filterKey]?.toString(); // convert to string first, because values might be a number
                  let match: boolean = false;

                  switch (true) {
                    case isArray(filterValue):
                      match = filterValue?.includes(metaField);
                      break;
                    default:
                      match = metaField?.toLowerCase()?.startsWith(filterValue?.toLowerCase());
                  }

                  if (match) {
                    filtered.push(task);
                  }
                });
                group.tasks = filtered;
                return group;
              });
            }

            break;
          case "assignees":
            if (!isEmpty(filters.active["assignees"])) {
              results = filter(
                tasks,
                (group) =>
                  filters.active[key].includes(extractUuidFromUrl(group.assignee.user)) ||
                  filters.active[key].includes(group.assignee.id) ||
                  group.assignee.id === UNASSIGNED_ID
              );
            }
            break;
          default:
            console.error(`invalid key ${key} received `);
        }
      });
      return results;
    },
    [filters.active, onApplyRemoteFilters, shouldTriggerAPI]
  );

  return { filteredTasks: tasksResults(tasks), filters, onApply, loadingFilters: loading };
};

export default useStoreFilters;
