import * as React from "react";

const DownIcon = (props) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.20019 0.999999L4.6002 5L1.0002 1" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default DownIcon;
