import styled from "styled-components";

import DayIcon from "./icons/day";
import NightIcon from "./icons/night";

export enum MAP_MODE {
  LIGHT = "LIGHT",
  DARK = "DARK",
}

interface ToggleMapMode {
  value: MAP_MODE | undefined;
  toggleMapState: () => void;
}

const ToggleMapMode = ({ value, toggleMapState }: ToggleMapMode) => {
  return (
    <Button>
      <label className={"switch"}>
        <input
          type="checkbox"
          checked={value === MAP_MODE.LIGHT}
          onChange={() => {
            toggleMapState();
          }}
        />
        <span className={"slider"}>
          <span>
            <DayIcon />
          </span>
          <span>
            <NightIcon />
          </span>
        </span>
      </label>
    </Button>
  );
};

export default ToggleMapMode;

const Button = styled.label`
  .switch {
    display: block;
    height: 33px;
  }

  input {
    display: none;
  }

  .slider {
    cursor: pointer;
    width: 60px;
    background-color: #2a2f39;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 5px 0;
  }

  s.slider:after {
    content: attr(day);
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    bottom: 13px;
    background-color: #545a64;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #ffffff;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider:before {
    border-radius: 50%;
  }
`;
