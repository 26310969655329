import { Children, cloneElement, isValidElement, MutableRefObject, useEffect, useRef, useState } from "react";

import { withErrorBoundary } from "@sentry/nextjs";
import { isEmpty } from "lodash";
import styled from "styled-components";
import useDeepCompareEffect from "use-deep-compare-effect";

import { MapFallbackError } from "components/error/map-fallback";
import { darkMode } from "components/map/utils";

const MapComponent = ({
  aspectRatio,
  setBounds,
  urlBounds,
  bounds,
  center,
  children,
  isDarkMode,
  onClick,
  onRightClick,
  onMouseMove,
  onLongPress,
  onBoundsChange,
  options,
  setMapAreaRef,
  zoom,
  height,
}: {
  autoZoom?: boolean;
  aspectRatio?: (v) => void;
  setBounds: (v) => void;
  urlBounds?: MutableRefObject<string>;
  bounds: google.maps.LatLngLiteral[];
  center: google.maps.LatLngLiteral;
  children: any;
  isDarkMode: boolean;
  maxZoom?: number | null;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onLongPress?: (e: google.maps.MapMouseEvent) => void;
  onBoundsChange?: (bounds: google.maps.LatLngBounds) => void;
  onRightClick: (location: any) => void;
  onMouseMove: (e: any) => void;
  options?: any;
  setMapAreaRef: any;
  zoom: number;
  height: string;
}) => {
  const [map, setMap] = useState<google.maps.Map>();

  const ref = useRef<HTMLDivElement>(null);

  const ctrlPosition = google.maps.ControlPosition.RIGHT_BOTTOM;
  const hideStreetView = false;

  const isDragging = useRef(false);
  const longPressTimer = useRef(null);

  const longPressInstance = useRef(onLongPress);

  useEffect(() => {
    if (ref.current && !map) {
      const mapInstance = new google.maps.Map(ref.current, {
        center,
        zoom,

        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: !hideStreetView,
        streetViewControlOptions: {
          position: ctrlPosition,
        },
        zoomControlOptions: {
          position: ctrlPosition,
        },
        clickableIcons: false,
        gestureHandling: "greedy",
        isFractionalZoomEnabled: false,
      });

      onRightClick && mapInstance.addListener("rightclick", (e) => onRightClick(e));
      if (setMapAreaRef) {
        setMapAreaRef(ref);
      }

      mapInstance.addListener("bounds_changed", () => {
        if (urlBounds) {
          urlBounds.current = mapInstance?.getBounds()?.toUrlValue();
          onBoundsChange && onBoundsChange(mapInstance?.getBounds());
        }
      });

      if (onLongPress) {
        const handleTouchStart = (e) => {
          if (!e) return;
          // if (e.touches.length !== 1) return; // Only consider single touch
          isDragging.current = false;

          if (longPressTimer.current) {
            clearTimeout(longPressTimer.current);
          }

          longPressTimer.current = setTimeout(() => {
            if (!isDragging.current) {
              longPressInstance.current(e);
            }
          }, 1200);
        };

        const handleTouchMove = () => {
          isDragging.current = true;
          clearTimeout(longPressTimer.current);
        };

        const handleTouchEnd = () => {
          clearTimeout(longPressTimer.current);
          longPressTimer.current = null;
        };

        mapInstance.addListener("touchstart", handleTouchStart);
        mapInstance.addListener("mousedown", handleTouchStart);
        mapInstance.addListener("dragstart", handleTouchStart);

        mapInstance.addListener("touchmove", handleTouchMove);
        mapInstance.addListener("drag", handleTouchMove);
        mapInstance.addListener("mousemove", handleTouchMove);

        mapInstance.addListener("touchend", handleTouchEnd);
        mapInstance.addListener("mouseup", handleTouchEnd);
        mapInstance.addListener("dragend", handleTouchEnd);
      }

      setMap(mapInstance);

      return () => {
        google.maps.event.clearListeners(mapInstance, "touchstart");
        google.maps.event.clearListeners(mapInstance, "mousedown");
        google.maps.event.clearListeners(mapInstance, "dragstart");
        google.maps.event.clearListeners(mapInstance, "drag");
        google.maps.event.clearListeners(mapInstance, "mousemove");
        google.maps.event.clearListeners(mapInstance, "touchend");
        google.maps.event.clearListeners(mapInstance, "mouseup");
        google.maps.event.clearListeners(mapInstance, "dragend");

        clearTimeout(longPressTimer.current);
        setMap(null);

        if (urlBounds) {
          urlBounds.current = "";
        }
      };
    }
  }, []);

  useDeepCompareEffect(() => {
    if (map && !isEmpty(bounds)) {
      const gbounds = new google.maps.LatLngBounds();

      bounds.map((bound) => {
        gbounds.extend(bound);
      });
      map.fitBounds(gbounds);
      map.panToBounds(gbounds);
    }
  }, [{ bounds, map, center }]);

  useDeepCompareEffect(() => {
    if (map && center) {
      map.setCenter(center);
    }
  }, [center]);

  useEffect(() => {
    if (map && onClick) {
      onClick && map.addListener("click", onClick);

      return () => {
        google.maps.event.clearListeners(map, "click");
      };
    }
  }, [map, onClick]);

  useEffect(() => {
    if (map && onMouseMove) {
      onMouseMove && map.addListener("mousemove", onMouseMove);

      return () => {
        google.maps.event.clearListeners(map, "mousemove");
      };
    }
  }, [map, onMouseMove]);

  useEffect(() => {
    if (map) {
      const bounds = map?.getBounds();

      if (bounds && urlBounds) {
        urlBounds.current = bounds?.toUrlValue();
      }

      map.setOptions({ ...options, styles: isDarkMode && darkMode });
    }
  }, [map, options, isDarkMode]);

  useEffect(() => {
    // Set map zoom and coordinates during component cleanup.
    return () => {
      if (map) {
        aspectRatio &&
          aspectRatio({
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng(),
            zoom: map.getZoom(),
          });

        setBounds && setBounds(null);
      }
    };
  }, [map]);

  return (
    <>
      <MapContainer ref={ref} height={height} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // @ts-ignore
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

const MapContainer = styled.div<{ height: string }>`
  width: 100%;
  height: ${(props) => props.height};
  display: flex;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    height: calc(100% - 35px);
  }

  .marker {
    font-weight: 600;
    color: #000000;
    margin-top: 40px;
    text-shadow: -0.8px 0 #fff, 0 0.8px #fff, 0.8px 0 #fff, 0 -0.8px #fff;
  }

  .center-marker {
    color: #ffffff !important;
  }
`;

export default withErrorBoundary(MapComponent, { fallback: MapFallbackError });
