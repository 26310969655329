import useSWR from "swr";
import { get, isEmpty } from "lodash";
import { useAppContext } from "contexts/app";
import { setSearchParams } from "utils/url-utils";

const MAX_RESULTS = 7;
export type ServiceBackends = "regio-ee" | "reach-u" | "nominatim" | "google" | "janaseta";

export const getRealTimeGeocodingBackendKey = (key: string) => {
  switch (key) {
    case "regio":
      return "reach-u";
    default:
      return key;
  }
};

interface AutoCompleteService {
  address: string;
}

const useAddressAutoCompleteRealTimeService = ({ address }: AutoCompleteService) => {
  const { httpClient, appData, configuration } = useAppContext();

  const shouldFetch = !isEmpty(address);
  const countryCode = get(configuration, "account.country_code");
  const url = new URL(`${window.location.origin}/api/realtime/geocoding/forward`);
  setSearchParams(url, "address", address);
  setSearchParams(url, "language", appData.language);
  setSearchParams(url, "max_results", MAX_RESULTS);

  const bounds = appData?.mapConfig?.boundsAsURL?.current;
  if (bounds) {
    setSearchParams(url, "viewport", bounds);
  }
  if (countryCode) {
    setSearchParams(url, "country", countryCode);
  }

  const { data, error, mutate } = useSWR(shouldFetch && url.toString(), httpClient.bareFetcher);

  const loading = shouldFetch && data === undefined && error === undefined;

  return { data, loading, mutate };
};

export default useAddressAutoCompleteRealTimeService;
