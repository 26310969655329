import React, { useState } from "react";

import { Badge, Dropdown, Space, Tooltip } from "antd";
import { countBy, find, first, groupBy, isEmpty, keyBy, last, remove, sortBy } from "lodash";
import Image from "next/image";
import styled from "styled-components";

import { getRouteContextMenu } from "components/dashboard/contextmenu/route";
import { getWorkerContextMenu } from "components/dashboard/contextmenu/worker";
import { Routes } from "components/dashboard/tasks/routes";
import TaskItem, { DropIndicator, DummyTask } from "components/dashboard/tasks/task";
import { onReorderAndAssignTasks } from "components/dashboard/utils";
import ContextMenu, { Menus } from "components/menus/context-menu";
import EllipsesIcon from "icons/ellipses";
import { Task } from "types/type";
import { getWorkerMarker } from "utils/markers-utils";
import { isOffline } from "utils/tasks";
import { extractUuidFromUrl } from "utils/uuid-utils";
import { WarningTwoTone } from "@ant-design/icons";
import { t } from "@lingui/macro";

const Container = styled.span`
  background: #3b4049;
  padding: 0 4px !important;
  border-radius: 2px;
  line-height: 12px;
`;

const TaskNumber = ({ no }: { no: string }) => {
  if (isEmpty(no)) {
    return null;
  }

  return <Container>{no}</Container>;
};

const StyledContextMenu = styled.span`
  padding: 0 5px;
`;

interface ContextMenuButtonProps {
  items: { label: string; action: () => void }[];
  rowData: any;
  onClick?: () => void;
}

const ContextMenuButton = ({ items, rowData, onClick }: ContextMenuButtonProps) => {
  const [showContextMenu, setShowContextMenu] = useState(false);

  return (
    <Dropdown
      open={showContextMenu}
      overlay={<Menus items={items} rowData={rowData} onChange={setShowContextMenu} />}
      trigger={["click"]}
      onOpenChange={() => {
        setShowContextMenu(!showContextMenu);
      }}
    >
      <StyledContextMenu
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick();
          setShowContextMenu(!showContextMenu);
        }}
      >
        <EllipsesIcon />
      </StyledContextMenu>
    </Dropdown>
  );
};

export const prioritizePickUpTasks = async (tasks, taskGroups, routeURL, dropPosition) => {
  await Promise.all(
    tasks.map((selectedTask) => {
      const group = taskGroups.find((group) => group.assignee.user === selectedTask.assignee);
      if (["pick_up", "drop_off"].includes(selectedTask.category)) {
        const isMovedOutOfFolder = isEmpty(selectedTask.route) && !isEmpty(routeURL);
        const isMovedIntoFolder = !isEmpty(selectedTask.route) && isEmpty(routeURL);
        const isMoveIntoAnotherFolder = selectedTask?.route && routeURL ? selectedTask.route !== routeURL : false;
        const filterTasksByRouteFn = (t) => (selectedTask.route ? t.route === selectedTask.route : true);

        group?.tasks.filter(filterTasksByRouteFn).map((task, taskPosition) => {
          // order can be an object.
          const selectedTaskOrderURL = selectedTask?.order?.url || selectedTask?.order;
          const taskOrderURL = task?.order?.url || task?.order;

          if (selectedTaskOrderURL && selectedTaskOrderURL === taskOrderURL && selectedTask.id !== task.id) {
            const isDropOffTaskAbovePickupTask =
              selectedTask.category === "drop_off" && task.category === "pick_up" && dropPosition <= taskPosition;
            const isPickUpTasksBelowDropOffTask =
              selectedTask.category === "pick_up" && task.category === "drop_off" && dropPosition >= taskPosition;

            if (
              isDropOffTaskAbovePickupTask ||
              isPickUpTasksBelowDropOffTask ||
              isMovedIntoFolder ||
              isMovedOutOfFolder ||
              isMoveIntoAnotherFolder
            ) {
              if (!tasks.find((t) => t.id === task.id)) {
                tasks.splice(dropPosition, 0, task);

                const sortWeights = { pick_up: 0, drop_off: 1 };
                tasks = sortBy(tasks, (o) => sortWeights[o.category]);
              }
            }
          }
        });
      }
    })
  );

  return tasks;
};

const handleReorder = async (
  info,
  dragOver,
  basePath,
  taskGroups,
  selectedTasks,
  featureFlagPrioritizePickup,
  defaultCreateTaskPos,
  setSelectedTasks,
  taskCommands,
  enableRoutesFolder = false
) => {
  const dragKey = info?.dragNode?.key?.split("__");
  const dropKey = dragOver?.split("__");
  const taskKey = dragKey?.[0];
  const dragGroupKey = dragKey[1];
  const routeKey = dropKey?.[2] || null;
  const routeURL = routeKey ? `${basePath}/routes/${routeKey}/` : null;
  const groupKey = dropKey[1] || dropKey[0];
  // flatten grouped tasks, this makes it easier to find/index tasks
  const taskList = taskGroups.reduce((result, taskGroup) => {
    return result.concat(...taskGroup.tasks.map((task, i) => ({ ...task, idx: i })));
  }, []);
  const tasksByOrder = groupBy(taskList, (v) => v?.order?.url || v?.order);

  if (!dropKey || !dragKey || !taskKey) {
    return;
  }

  const group = find(taskGroups, (g) => g.assignee.id === groupKey);
  if (!group) {
    return;
  }

  const groupedTaskByAssignee = enableRoutesFolder
    ? {
        ...group,
        tasks: group.tasks.filter((task) => task.route === routeURL),
      }
    : group;

  let tasks = [...selectedTasks];

  // find and add tasks related by the same order
  await Promise.all(
    tasks.map((selectedTask) => {
      const selectedTaskOrderUrl = selectedTask?.order?.url || selectedTask?.order;
      const isTaskReAssigned = dragKey[1] !== groupedTaskByAssignee.assignee.id; // reassigned to a different assignee

      if (selectedTaskOrderUrl && isTaskReAssigned) {
        const tasksWithSameOrder = tasksByOrder[selectedTaskOrderUrl];
        if (tasksWithSameOrder) {
          tasksWithSameOrder.forEach((task) => {
            // Only add tasks that are not already present in the `tasks` array
            if (!tasks.find((t) => t.id === task.id)) {
              tasks.push(task);
            }
          });
        }
      }
    })
  );

  tasks = tasks.sort((a, b) => {
    return a.position - b.position;
  });
  const lastTask = last(tasks);
  let dropPosition = groupedTaskByAssignee.tasks.findIndex((t) => t.id === dropKey[0]);
  if (dropPosition < 0) {
    dropPosition = 0;
  } else {
    // increment drop position if new tasks is added.
    if (!enableRoutesFolder || routeURL === lastTask?.route) {
      dropPosition = dropPosition + 1;
    }
    if (routeURL && routeURL !== lastTask?.route) {
      dropPosition = dropPosition + 1;
    }
  }

  // prioritize pick_up before drop_off  tasks when moving related by order id
  if (featureFlagPrioritizePickup) {
    tasks = await prioritizePickUpTasks(tasks, taskGroups, routeURL, dropPosition);
  }

  const isSelectedPresent = tasks.find((task) => task.id === taskKey);

  if (!isSelectedPresent) {
    const draggedTaskGroup = taskGroups.find((g) => extractUuidFromUrl(g.assignee.user) === dragGroupKey);
    if (!draggedTaskGroup) {
      return;
    }
    const task = draggedTaskGroup.tasks.find((t) => t.id === taskKey);

    if (!tasks.find((t) => t.id === task.id)) {
      tasks.unshift(task);
    }
    setSelectedTasks([...tasks]);
  }

  const commands = onReorderAndAssignTasks(
    defaultCreateTaskPos,
    false,
    dropPosition,
    groupedTaskByAssignee,
    tasks,
    routeURL
  );

  taskCommands({
    type: "SEND",
    data: commands,
  });
};

const getTreeData = (
  taskGroups,
  expandedTasks,
  setExpandedTasks,
  setSelectedGroup,
  selectedTasks,
  openedTasks,
  dispatchActions,
  setCenter,
  setBounds,
  setSelectedTasks,
  setEditAbleTask,
  handleSelect,
  editTask,
  handleMultipleSelect,
  handleCheckBoxSelect,
  onMutateGroupedTasks,
  handleCloneTask,
  taskCommands,
  enableTasksRoutes,
  setSelectedRoute,
  changeRouteState,
  isManager,
  setNodeData,
  onReorder,
  isMobileView
) => {
  return taskGroups.map((group, _) => {
    const worker = group?.assignee?.display_name || group?.assignee?.email || group?.assignee?.id;
    const issues = group.tasks.filter((task) => !isEmpty(task?.issues));

    const expandableIcon = getWorkerMarker(
      group?.assignee?.is_on_duty,
      group?.assignee?.is_on_duty && isOffline(group?.assignee?.last_location?.updated_at, 15, "minute"),
      !expandedTasks.includes(group?.assignee.id) ? 90 : 180
    );

    const onSetSelectedGroup = () => {
      setSelectedGroup(group.assignee.id);
    };

    const openWorkerHandler = () => {
      if (group.tasks.length === 0) {
        if (expandedTasks.includes(group.assignee.id)) {
          remove(expandedTasks, (t) => t === group.assignee.id);
        } else {
          expandedTasks.push(group.assignee.id);
        }
        setExpandedTasks([...expandedTasks]);
        return;
      }

      if (openedTasks.includes(group.assignee.id)) {
        remove(openedTasks, (t) => t === group.assignee.id);
      } else {
        openedTasks.push(group.assignee.id);
        onSetSelectedGroup();
      }
      setExpandedTasks([...openedTasks]);
    };

    let taskLength = `${group.tasks.length}`;
    if (group.tasksCount && group.tasks.length != group.tasksCount) {
      taskLength = `${group.tasks.length}/${group.tasksCount}`;
    }
    if (group.tasks.length === 0) {
      taskLength = "";
    }

    const countByKeys = countBy(selectedTasks, "assignee");
    const count = !expandedTasks.includes(group.assignee.id)
      ? countByKeys[group.assignee.user] || undefined
      : undefined;

    const items = getWorkerContextMenu(
      false,
      group,
      dispatchActions,
      setCenter,
      setBounds,
      isManager,
      null,
      false,
      openedTasks,
      enableTasksRoutes
    );
    const assigneeId = group.assignee.id;
    const onDragOver = () => {
      if (setNodeData) {
        setNodeData(assigneeId);
      }

      document
        .getElementById(assigneeId)
        ?.setAttribute(
          "style",
          "background: linear-gradient(to right, transparent 33%, #ffffff 0%) top/10px 1px repeat-x," +
            "linear-gradient(transparent 33%, #ffffff 0%) right/1px 10px repeat-y," +
            "linear-gradient(to right, transparent 33%, #ffffff 0%) bottom/10px 1px repeat-x," +
            "linear-gradient(transparent 33%, #ffffff 0%) left/1px 10px repeat-y;"
        );
    };

    const onDragLeave = () => {
      document
        .getElementById(assigneeId)
        ?.setAttribute(
          "style",
          "background: linear-gradient(to right, transparent 33%, #2a2f39 0%) top/10px 1px repeat-x," +
            "linear-gradient(transparent 33%, #2a2f39 0%) right/1px 10px repeat-y," +
            "linear-gradient(to right, transparent 33%, #2a2f39 0%) bottom/10px 1px repeat-x," +
            "linear-gradient(transparent 33%, #2a2f39 0%) left/1px 10px repeat-y;"
        );
    };

    const onDone = () => {
      onDragLeave();

      if (onReorder) {
        onReorder();
      }
    };

    const title = (
      <div onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDone} id={assigneeId}>
        <Badge count={count}>
          {/* @ts-ignore*/}
          <ContextMenu key={assigneeId} items={items} rowData={taskGroups}>
            <Space align={"center"} className={"header"}>
              <Image
                src={expandableIcon}
                alt="expandable-icon"
                width={14}
                height={14}
                onClick={() => {
                  openWorkerHandler();
                }}
              />
              <span
                className={"header-text"}
                onClick={() => {
                  openWorkerHandler();
                }}
              >
                <Space size={6}>
                  {worker.toUpperCase()} {taskLength && <TaskNumber no={taskLength} />}
                  {!isEmpty(issues) && (
                    <Tooltip title={t`There are issues with some tasks`}>
                      <WarningTwoTone twoToneColor={"red"} />
                    </Tooltip>
                  )}
                  <ContextMenuButton items={items} rowData={taskGroups} />
                </Space>
              </span>
            </Space>
          </ContextMenu>
        </Badge>
      </div>
    );

    const keyedRoutes = keyBy(group.routes, "url");
    const ungroupedTasks =
      group.tasks?.filter((task: Task) => {
        return isEmpty(task.route) || isEmpty(keyedRoutes[task.route]);
      }) || [];

    const routes =
      sortBy(
        {
          ...group?.routes,
        },
        "name"
      ) || [];

    // dummy task used to create index position illusion
    const ungroupedTask = first(ungroupedTasks);
    if (ungroupedTask) {
      ungroupedTasks.unshift({ ...first(ungroupedTasks), id: "index" });
    }

    const ungrouped = ungroupedTasks?.map((task, index) => {
      // @ts-ignore
      const orderId = task?.order?.id || task?.order;
      const isSelected = selectedTasks.findIndex((t) => t.id === task.id) > -1;
      const isConnectedToSelectedTask =
        selectedTasks.findIndex((t) => orderId && (t?.order?.id || t?.order) === orderId) > -1;
      const key = `${task.id}__${group.assignee.id}`;

      return {
        key: key,
        className: `ungrouped-task-item ${index === 0 && "first-ungrouped-task-item"}`,
        title:
          index === 0 ? (
            <DummyTask taskKey={key} setNodeData={setNodeData} onReorder={onReorder} />
          ) : (
            <>
              <TaskItem
                setNodeData={setNodeData}
                taskKey={key}
                index={index}
                task={task}
                tasks={selectedTasks}
                clearTasks={setSelectedTasks}
                editTask={editTask}
                onSetEditAbleTask={setEditAbleTask}
                onSetSelectedGroup={onSetSelectedGroup}
                onSetSelectedRoute={() => setSelectedRoute(task.route)}
                handleSelect={handleSelect}
                handleMultipleSelect={handleMultipleSelect}
                handleCheckBoxSelect={handleCheckBoxSelect}
                handleSingletonSelect={setSelectedTasks}
                isSelected={isSelected}
                isHighlighted={isConnectedToSelectedTask}
                onMutateGroupedTasks={onMutateGroupedTasks}
                dispatchActions={dispatchActions}
                onSetTasksCenter={setCenter}
                handleCloneTasks={handleCloneTask}
                taskCommands={taskCommands}
                taskGroups={taskGroups}
                onReorder={onReorder}
                isMobileView={isMobileView}
              />
              <DropIndicator id={key} />
            </>
          ),
      };
    });

    const grouped = routes.map((route) => {
      const tasks = group?.tasks?.filter((task: Task) => {
        return task.route === route.url;
      });

      const renderTasks = tasks?.map((task: Task, index) => {
        // @ts-ignore
        const orderId = task?.order?.id || task?.order;
        const isSelected = selectedTasks.findIndex((t) => t.id === task.id) > -1;
        const isConnectedToSelectedTask =
          selectedTasks.findIndex((t) => orderId && (t?.order?.id || t?.order) === orderId) > -1;
        const key = `${task.id}__${group.assignee.id}__${route.id}`;

        return {
          key: key,
          className: `route ${index === tasks.length - 1 && "last-task-item"}`,
          title: (
            <>
              <TaskItem
                setNodeData={setNodeData}
                taskKey={key}
                index={index + 1}
                task={task}
                tasks={selectedTasks}
                clearTasks={setSelectedTasks}
                editTask={editTask}
                onSetEditAbleTask={setEditAbleTask}
                onSetSelectedGroup={onSetSelectedGroup}
                onSetSelectedRoute={() => setSelectedRoute(task.route)}
                handleSelect={handleSelect}
                handleMultipleSelect={handleMultipleSelect}
                handleCheckBoxSelect={handleCheckBoxSelect}
                handleSingletonSelect={setSelectedTasks}
                isSelected={isSelected}
                isHighlighted={isConnectedToSelectedTask}
                onMutateGroupedTasks={onMutateGroupedTasks}
                dispatchActions={dispatchActions}
                onSetTasksCenter={setCenter}
                handleCloneTasks={handleCloneTask}
                taskCommands={taskCommands}
                taskGroups={taskGroups}
                isGrouped={true}
                onReorder={onReorder}
                isMobileView={isMobileView}
              />
              <DropIndicator id={key} />
            </>
          ),
        };
      });

      const key = `routes__${group.assignee.id}__${route.id}`;
      const isOpened = openedTasks.includes(key);

      const onToggleRoute = () => {
        if (isOpened) {
          remove(openedTasks, (t) => t === key);
          setSelectedRoute("");
        } else {
          openedTasks.push(key);
          setSelectedRoute(route.url);
        }
        setExpandedTasks([...openedTasks]);
      };

      const routeMenuItems: any = getRouteContextMenu(
        route,
        group.assignee,
        tasks,
        dispatchActions,
        setCenter,
        setBounds,
        openedTasks,
        (action, archiveAction) => changeRouteState(route.id, action, archiveAction),
        isManager
      );

      let taskLength = `${tasks?.length}`;
      const routeCount = group.routesCount?.[route.url];
      if (routeCount && tasks?.length != routeCount) {
        taskLength = `${taskLength}/${routeCount}`;
      }
      if (tasks?.length === 0) {
        taskLength = "";
      }

      return {
        key: key,
        disabled: true,
        className: isOpened ? `opened-route ${tasks?.length === 0 && "empty-route"}` : "closed-route",
        title: (
          <Routes
            assigneeId={group.assignee.id}
            groups={taskGroups}
            isOpened={isOpened}
            title={route.name || route.description}
            count={taskLength || ""}
            onToggleRoute={onToggleRoute}
            items={routeMenuItems}
            routeKey={key}
            state={route.state}
            onReorder={onReorder}
            setNodeData={setNodeData}
          />
        ),
        children: renderTasks,
      };
    });

    const children = grouped.concat(ungrouped);

    return {
      key: group.assignee.id,
      disabled: true,
      className: "assignee-node",
      title: title,
      children: children,
    };
  });
};

const onDragStart = (draggableId, setIsDragging, selectedTasks, taskGroups, setSelectedTasks) => {
  setIsDragging(draggableId);

  if (!selectedTasks.map((t) => t.id).includes(draggableId)) {
    selectedTasks.length = 0;
  }

  taskGroups.map((groups) => {
    groups.tasks.map((task) => {
      if (task.id === draggableId) {
        const includesTaskId = selectedTasks.map((t) => t.id).includes(task.id);
        if (!includesTaskId) {
          selectedTasks.push(task);
        }
      }
    });
  });

  setSelectedTasks([...selectedTasks]);

  let draggingTasksLength = selectedTasks.length;
  selectedTasks.map((t) => {
    taskGroups.map((groups) => {
      groups.tasks.map((task) => {
        const selectedTaskOrderUrl = task.order?.url || task.order;
        const taskOrderUrl = t.order?.url || t.order;

        if (selectedTaskOrderUrl && selectedTaskOrderUrl === taskOrderUrl) {
          if (!selectedTasks.map((task) => task.id).includes(task.id)) {
            draggingTasksLength++;
          }
        }
      });
    });
  });

  return draggingTasksLength;
};

export { TaskNumber, ContextMenuButton, handleReorder, getTreeData, onDragStart };
