import { useEffect, useRef } from "react";
import Stats from "stats.js";
import { useRouter } from "next/router";

const StatsJs = () => {
  const router = useRouter();

  const statsRef = useRef<any>();

  const stats = Boolean(router?.query?.["stats"] === "true");
  const showStats = stats === true;

  useEffect(() => {
    if (showStats) {
      const stats = new Stats();
      stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
      stats.showPanel(1);
      stats.showPanel(2);

      const updateStats = () => {
        stats.begin();
        // monitored code goes here
        stats.end();
        requestAnimationFrame(updateStats);
      };

      updateStats();

      // Attach stats.dom manually to a ref
      statsRef?.current?.appendChild(stats.dom);

      return () => {
        // Clean up stats.dom on unmount
        statsRef?.current?.removeChild(stats.dom);
      };
    }
  }, [showStats]);

  return showStats ? <div ref={statsRef} style={{ position: "fixed", top: 0, left: 12, zIndex: 999 }} /> : null;
};

export default StatsJs;
