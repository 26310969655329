import { t } from "@lingui/macro";
import { isEmpty } from "lodash";

import { setLatLngPrecision } from "components/map/utils";
import { Route, ROUTE_ACTION } from "types/type";

export const getRouteContextMenu = (
  route: Route,
  assignee,
  tasks,
  dispatchActions,
  onCenterWorker,
  setBounds,
  opened = [],
  changeRouteState: (action: string, archiveAction?: string) => void,
  isManager
) => {
  const state = route.state;

  return [
    {
      key: 0,
      label: t`Center in map`,
      action: () => {
        if (onCenterWorker) {
          const workerLocation = assignee?.last_location?.location;
          const coordinates = [];
          if (workerLocation) {
            const workerCoordinates = {
              lat: workerLocation.coordinates[1],
              lng: workerLocation.coordinates[0],
            };
            onCenterWorker(setLatLngPrecision(workerCoordinates));
            coordinates.push(workerCoordinates);
            if (opened.includes(assignee["id"])) {
              tasks.map((task) => {
                const coordinate = task?.address?.location?.coordinates;
                if (!isEmpty(coordinate)) {
                  coordinates.push({
                    lat: coordinate[1],
                    lng: coordinate[0],
                  });
                }
              });
            }
            if (coordinates) {
              setBounds([...coordinates]);
            }
          }
        }
      },
    },
    {
      key: 1,
      label: t`Optimize worker route`,
      disabled: tasks.length === 0,
      action: () => {
        dispatchActions({ type: "OPTIMIZE", routeId: route.id });
        dispatchActions({ type: "TASKS", value: tasks });
      },
    },
    {
      key: 2,
      label: t`Assign to another worker`,
      action: () => {
        dispatchActions({ type: "CHANGE_ASSIGNEE" });
        dispatchActions({ type: "TASKS", value: tasks, routeId: route.id });
      },
    },
    {
      key: 3,
      label: t`Change tasks state`,
      action: () => {
        dispatchActions({ type: "CHANGE_STATE" });
        dispatchActions({ type: "TASKS", value: tasks });
      },
    },
    {
      key: 4,
      label: t`Edit route`,
      action: () => {
        dispatchActions({ type: "OPEN_ROUTE_PROMPT", routeId: route.id, assignee: assignee.user });
      },
      disabled: !isManager,
    },
    {
      key: 5,
      label: state === ROUTE_ACTION.DRAFT ? t`Activate route` : t`Convert to draft`,
      action: () => {
        changeRouteState(state === ROUTE_ACTION.DRAFT ? ROUTE_ACTION.ACTIVATE : ROUTE_ACTION.DRAFT);
      },
      disabled: !isManager,
    },
    {
      key: 6,
      label: t`Archive route`,
      cascade: [
        {
          key: 1,
          label: t`Unassign tasks`,
          action: () => {
            changeRouteState(ROUTE_ACTION.ARCHIVE, "unassign");
          },
          disabled: !isManager,
        },
        {
          key: 2,
          label: t`Unlink tasks`,
          action: () => {
            changeRouteState(ROUTE_ACTION.ARCHIVE, "unlink");
          },
          disabled: !isManager,
        },
        {
          key: 3,
          label: t`Complete tasks`,
          action: () => {
            changeRouteState(ROUTE_ACTION.ARCHIVE, "complete");
          },
          disabled: !isManager,
        },
        {
          key: 4,
          label: t`Cancel Tasks`,
          action: () => {
            changeRouteState(ROUTE_ACTION.ARCHIVE, "cancel");
          },
          disabled: !isManager,
        },
      ],
      disabled: !isManager,
    },
  ];
};
