import { QuestionCircleOutlined } from "@ant-design/icons";
import { t, Trans } from "@lingui/macro";
import { message } from "antd";
import he from "he";
import { find, first, forEach, includes, isEmpty, keyBy, keys, last, pick, template } from "lodash";

import { onReorderAndAssignTasks } from "components/dashboard/utils";
import { DATA_TYPE, FILTER_CONDITION } from "components/table/types";
import { ROUTING_API } from "contexts/app";
import AssignmentIcon from "icons/assignment";
import DropOffIcon from "icons/drop-off";
import PickUpIcon from "icons/pickup";
import AcceptedIcon from "icons/task-events/accepted";
import ActiveIcon from "icons/task-events/active";
import AssignedIcon from "icons/task-events/assigned";
import AwayIcon from "icons/task-events/away";
import CancelledIcon from "icons/task-events/cancelled";
import CompleteIcon from "icons/task-events/complete";
import CreatedIcon from "icons/task-events/created";
import FailedIcon from "icons/task-events/failed";
import NearIcon from "icons/task-events/near";
import TransitIcon from "icons/task-events/transit";
import UnAcceptedIcon from "icons/task-events/unaccepted";
import UnAssignedIcon from "icons/task-events/unassigned";
import UpdatedIcon from "icons/task-events/updated";
import { MetaField, Route, Task } from "types/type";
import { parseDate, parseDurationAsSeconds } from "utils/datetime-utils";
import { httpStatusCodes } from "utils/status-codes-utils";
import { extractUuidFromUrl } from "utils/uuid-utils";
import ScheduledIcon from "icons/task-events/scheduled";
import { prioritizePickUpTasks } from "components/dashboard/tasks/utils";

export const createMetafieldText = (metafields, metafieldValues) => {
  let text = "";

  const metafieldsByFieldName = keyBy(metafields, "field_name");
  keys(metafieldValues).forEach((key) => {
    if (metafieldsByFieldName[key]) {
      text += `${metafieldsByFieldName[key].label}: ${metafieldValues[key]}`;
    }
  });
  return text;
};

export const createWaypointAdditionalText = (waypointValue) => {
  let phones = "";

  waypointValue?.contact?.phones?.length &&
    waypointValue.contact.phones.forEach((phone) => {
      if (phone && phone.length) {
        phones += `${phone}, `;
      }
    });

  return `${waypointValue?.address?.formatted_address || waypointValue?.address?.raw_address || ""} ${waypointValue?.contact?.name || ""
    } ${waypointValue?.contact?.company || ""}`;
};

const cloneProperties = [
  "account",
  "address",
  "assignee",
  "category",
  "contact",
  "created_at",
  "description",
  "duration",
  "scheduled_time",
  "state",
  "orderer_name",
];

const getStateColor = (state) => {
  switch (state) {
    case "pending":
      return "blue";
    case "started":
      return "blue";
    case "ready":
      return "blue";
    case "completed":
      return "green";
    case "over_quota":
      return "orange";
    case "failed":
      return "red";
    default:
      return null;
  }
};

const getAccountRoleByUser = (fetchUser, url, isManager) => {
  const defaultRes = {
    display_name: "-",
  };

  if (!url) {
    return defaultRes;
  }

  const account = fetchUser(url);
  if (account && isManager) return account;
  if (!isManager && account?.user === url) return account;

  return { display_name: extractUuidFromUrl(url) };
};

const unknownTaskStateColor = "#FFBF00";

const taskStateObject = [
  {
    name: "unassigned",
    label: <Trans>Unassigned</Trans>,
    color: "#989898",
    tint: "#f8f8f8",
  },
  {
    name: "active",
    label: <Trans>Active</Trans>,
    color: "#a5ff00",
    tint: "#effff0",
  },
  {
    name: "assigned",
    label: <Trans>Assigned</Trans>,
    color: "#54c7fc",
    tint: "#edf9ff",
  },
  {
    name: "accepted",
    label: <Trans>Accepted</Trans>,
    color: "#0068e9",
    tint: "#eff5ff",
  },
  {
    name: "failed",
    label: <Trans>Failed</Trans>,
    color: "#f9fe4a",
    tint: "#fff5e5",
  },
  {
    name: "transit",
    label: <Trans>Transit</Trans>,
    color: "#9b29ff",
    tint: "#f6edff",
  },
  {
    name: "scheduled",
    label: <Trans>Scheduled</Trans>,
    color: "rgba(255, 171, 0, 1.0)",
    tint: "#f8f8f8",
  },
  {
    name: "completed",
    label: <Trans>Completed</Trans>,
    color: "#00B76A",
    tint: "#c8f8e4",
  },
  {
    name: "cancelled",
    label: <Trans>Cancelled</Trans>,
    color: "#ff2851",
    tint: "#ffe8ef",
  },
];

export const getTaskState = (v) => {
  const state = taskStateObject.find((val) => val.name === v);
  if (state) {
    return state;
  }

  return {
    name: v,
    label: v,
    color: "",
    tint: "",
  };
};

const taskEventStates = [
  {
    name: "create",
    color: "#FFF",
    icon: <CreatedIcon />,
  },
  {
    name: "assign",
    color: "#54c7fc",
    icon: <AssignedIcon fill="#54c7fc" />,
  },
  {
    name: "unassign",
    color: "#949494",
    icon: <UnAssignedIcon fill="#949494" />,
  },
  {
    name: "reject",
    color: "#FF1A5E",
    icon: <CancelledIcon fill="#00b76a" />,
  },
  {
    name: "accept",
    color: "#0068E9",
    icon: <AcceptedIcon fill="#0068E9" />,
  },
  {
    name: "unaccept",
    color: "#1AC1D2",
    icon: <UnAcceptedIcon fill="#1AC1D2" />,
  },
  {
    name: "transit",
    color: "#FA9926",
    icon: <TransitIcon fill="#FA9926" />,
  },
  {
    name: "activate",
    color: "#9A39FB",
    icon: <ActiveIcon fill="#9A39FB" />,
  },
  {
    name: "complete",
    color: "#60FA67",
    icon: <CompleteIcon fill="#60FA67" />,
  },
  {
    name: "fail",
    color: "#E3E839",
    icon: <FailedIcon fill="#E3E839" />,
  },
  {
    name: "cancel",
    color: "#ff2851",
    icon: <CancelledIcon fill="#ff2851" />,
  },
  {
    name: "schedule",
    color: "rgba(255, 171, 0, 1.0)",
    icon: <ScheduledIcon />,
  },
  {
    name: "assignee_near",
    color: "#fff",
    icon: <NearIcon />,
  },
  {
    name: "assignee_away",
    color: "#fff",
    icon: <AwayIcon />,
  },
  {
    name: "updated",
    color: "#ff2851",
    icon: <UpdatedIcon />,
  },
];

export const getEventState = (state) => {
  const event = taskEventStates.find((val) => val.name === state);
  if (event) {
    return event;
  }
  return null;
};

export const getTaskStateFromCommand = (action) => {
  switch (action) {
    case "unassign":
      return "unassigned";
    case "accept":
      return "accepted";
    case "unaccept":
      return "assigned";
    case "transit":
      return "transit";
    case "complete":
      return "completed";
    case "fail":
      return "failed";
    case "cancel":
      return "cancelled";
    case "activate":
      return "active";
    case "create":
      return "created";
    case "assign":
    case "reassign":
      return "assigned";
    case "assignee_away":
      return "away";
    case "assignee_near":
      return "near";
    case "updated":
      return "updated";
    default:
      return action;
  }
};

export const getPlaceDetails = (details, reverse) => {
  const addressData: any = {};

  if (!details?.address_components) {
    return "";
  }

  forEach(details.address_components, (component) => {
    const type = component.types[0];
    addressData[type] = component.long_name;

    if (type === "country") {
      addressData.country_code = component.short_name;
    }
  });

  const result = {
    raw_address: details?.raw_address || details.formatted_address,
    formatted_address: details.formatted_address,
    google_place_id: details.place_id,
    point_of_interest: "",
    street: addressData?.route || "",
    house_number: addressData?.street_number || "",
    city: addressData?.locality || "",
    state: addressData?.administrative_area_level_1 || "",
    postal_code: addressData?.postal_code || "",
    country: addressData?.country || "",
    country_code: addressData?.country_code || "",
  };

  if (details.geometry) {
    const pos = [details.geometry.location.lng(), details.geometry.location.lat()];
    result["location"] = {
      type: "Point",
      coordinates: reverse ? reverseCoordinates(pos as [number, number]) : pos,
    };
  }

  return result;
};

export const getCategoryWithTextIcon = (type, style = null) => {
  switch (type) {
    case "assignment":
      return { icon: <AssignmentIcon style={style ? { ...style } : {}} />, text: <Trans>Assignment</Trans> };
    case "pick_up":
      return { icon: <PickUpIcon style={style ? { ...style } : {}} />, text: <Trans>Pick up</Trans> };
    case "drop_off":
      return { icon: <DropOffIcon style={style ? { ...style } : {}} />, text: <Trans>Drop off</Trans> };
  }
  return { icon: null, text: null };
};

const getFieldType = (metafield) => {
  switch (metafield.value_type) {
    case "choice":
      return {
        type: DATA_TYPE.CHOICE,
        options: metafield?.choices
          ? metafield.choices.map((choice) => ({
            value: choice,
            label: choice,
          }))
          : [],
        filter: metafield.is_searchable
          ? {
            type: DATA_TYPE.CHOICE,
            values: metafield?.choices
              ? metafield.choices.map((choice) => ({
                value: choice,
                label: choice,
              }))
              : [],
          }
          : undefined,
      };
    case "string":
      return {
        type: DATA_TYPE.TEXT,
        filter: metafield.is_searchable
          ? {
            type: DATA_TYPE.TEXT,
            conditions: [FILTER_CONDITION.EQUALS, FILTER_CONDITION.CONTAINS],
          }
          : undefined,
      };
    case "boolean":
      return {
        type: DATA_TYPE.BOOLEAN,
        filter: metafield.is_searchable
          ? {
            type: DATA_TYPE.TEXT,
            conditions: [FILTER_CONDITION.TRUE, FILTER_CONDITION.FALSE],
          }
          : undefined,
      };
    case "decimal":
    case "integer":
      return {
        type: DATA_TYPE.NUMBER,
        filter: metafield.is_searchable
          ? {
            type: DATA_TYPE.NUMBER,
            conditions: [
              FILTER_CONDITION.GTE,
              FILTER_CONDITION.GT,
              FILTER_CONDITION.LTE,
              FILTER_CONDITION.LT,
              FILTER_CONDITION.EQUALS,
            ],
          }
          : undefined,
      };
    default:
      break;
  }
};

export const parseMetafieldsToColumns = (metafields) => {
  if (!metafields) {
    return [];
  }

  let result = [];

  metafields.map((v) => {
    if (v.show_in_list_view) {
      result.push({
        key: "metafields__" + v.field_name,
        title: v.label,
        width: 100,
        defaultIfNull: "-",
        dataIndex: ["metafields", v.field_name],
        group: "metafields",
        isVisible: false,
        ...getFieldType(v),
      });
    }
  });

  return result;
};

const isTaskCompletedOrEmpty = (tasks) => {
  if (tasks) {
    return find(tasks, (t) => {
      return !includes(["completed", "cancelled"], t.state);
    });
  }
};

export const transformAssigneesToOptions = (assignees) => {
  return assignees?.map((val, index) => ({
    value: extractUuidFromUrl(val.user),
    label: val.display_name || val.email,
    inactiveAt: val.deleted_at,
    key: index,
  }));
};

const isOffline = (lastUpdatedAt, laterThan, unit) => {
  let isOffline = false;

  if (!lastUpdatedAt) {
    return isOffline;
  }

  if (lastUpdatedAt) {
    const lastUpdated = parseDate(lastUpdatedAt);
    const updatedAt = lastUpdated.add(laterThan, unit);
    if (parseDate().isAfter(updatedAt)) {
      isOffline = true;
    }
  }

  return isOffline;
};

const getTaskCategoryIcon = (category, size = 12) => {
  switch (category) {
    case "assignment":
      return <AssignmentIcon width={size} />;
    case "pick_up":
      return <PickUpIcon width={size} />;
    case "drop_off":
      return <DropOffIcon width={size} />;
    default:
      return <QuestionCircleOutlined width={size} />;
  }
};

const MIN_POSITION = 0.0;
const MAX_POSITION = 253402300799.0;

// calculate positions generate task position using the average of task above and task below.
const calculatePositions = (prev, next, n) => {
  return [...Array(n).keys()].map((i) =>
    Math.max(MIN_POSITION, Math.max(MIN_POSITION, prev + (i + 1) * ((next - prev) / (n + 1))))
  );
};

// would return modified tasks with updated positions
const mergeTasks = (src = [], dest = [], destIdx) => {
  switch (true) {
    case dest.length === 0:
      const position = Math.floor(Date.now() / 1000); // position using unix timestamps in seconds.
      return src.map((task, i) => {
        const increment = i * 100;
        task.position = position + increment;
        return task;
      });
    default:
      /**
       *  This block of code merges dragged task(s) and tasks in an assignee.
       *  The General idea is to assign the dragged task(s) a position which is gotten from the average of the task above drop position or MIN_POSITION
       *  and task below drop position || MAX_POSITION
       *  Also included is an algorithm to space tasks whenever the position between tasks is less than 1.
       */

      const srcIds = src.map((task) => task.id);

      //split assignees tasks [dest] into two parts based on drop index (before drop index [destPrevious] and after drop index destNext [destNext]).
      const destNext = dest.slice(destIdx).filter((task) => !srcIds.includes(task.id));
      const destPrevious = dest.slice(0, destIdx).filter((task) => !srcIds.includes(task.id));
      const lastDestPreviousPosition = last(destPrevious)?.position;
      const firstDestNextPosition = first(destNext)?.position;

      // populate src with last destPrevious task if position is greater than drop task position -1 or if previous task position is <= MIN_POSITION.
      while (
        destPrevious.length &&
        (last(destPrevious)?.position > (firstDestNextPosition || MAX_POSITION) - 1 ||
          last(destPrevious)?.position <= MIN_POSITION + 1)
      ) {
        src.unshift(destPrevious.pop());
      }

      // populate src with first destNext task if position is less than drop task position + 1 or if next task position is >= MAX_POSITION
      while (
        destNext.length &&
        (first(destNext)?.position < (lastDestPreviousPosition || MIN_POSITION) + 1 ||
          first(destNext)?.position >= MAX_POSITION - 1)
      ) {
        src.push(destNext.shift());
      }

      // retrieve min and max position from destPrevious and destNext respectively and use constants (MIN_POSITION,MAX_POSITION ) as defaults
      let prev = destPrevious.length ? last(destPrevious)?.position : MIN_POSITION;
      let next = destNext.length ? first(destNext)?.position : MAX_POSITION;

      calculatePositions(prev, next, src.length).map((pos, i) => {
        src[i].position = pos;
      });

      return src;
  }
};

const insertTask = (pos, task, dest) => {
  dest.splice(pos, 0, task);
  return dest;
};

export const sameDayFormat = (
  date: string,
  dateFormat: string = "YYYY.MM.DD",
  timeFormat: string = "HH.mm",
  shouldFormat: boolean,
  comparator = null
) => {
  if (!date) {
    return "∞";
  }

  const now = parseDate();

  if (comparator) {
    // @ts-ignore
    if (parseDate(date).isSameOrBefore(parseDate(comparator), "day")) {
      return parseDate(date).format(timeFormat);
    }
  }

  if (shouldFormat && now.isSame(date, "day")) {
    return parseDate(date).format(timeFormat);
  }

  return parseDate(date).format(`${dateFormat} ${timeFormat}`);
};

interface ParseTemplate {
  tmpl: string;
  task: Task;
  separator: string;
  dateFormat: string;
  timeFormat: string;
  applyTimeFormat: boolean;
}

const parseTemplate = ({
  tmpl,
  task,
  separator,
  dateFormat = "DD.MM.YYYY",
  timeFormat = "HH.mm",
  applyTimeFormat,
}: ParseTemplate) => {
  const data = { ...task };
  // @ts-ignore .. date types from openapi
  data["duration"] = data?.duration?.length === 8 ? data.duration.slice(0, -3) : data?.duration;
  if (applyTimeFormat) {
    data["scheduled_time"] = data?.scheduled_time
      ? sameDayFormat(data?.scheduled_time, dateFormat, timeFormat, true)
      : undefined;
  } else {
    data["scheduled_time"] = data?.scheduled_time
      ? parseDate(data?.scheduled_time).format(`${dateFormat} ${timeFormat}`)
      : undefined;
  }

  let res = [];

  if (isEmpty(tmpl) || isEmpty(data)) {
    return res;
  }

  try {
    res = template(tmpl)(data)?.split(separator);

    return res.map((line) => {
      return !isEmpty(line) ? he.decode(line) : "";
    });
  } catch (error) {
    // ignore templates error as blank would be returned
  }

  return [""];
};

const renderCoordinates = (coordinates, reverse = true) => {
  if (isEmpty(coordinates)) {
    return "";
  }

  if (typeof coordinates === "string") {
    return `${coordinates}`;
  }
  if (reverse) {
    return `${coordinates[1]}, ${coordinates[0]}`;
  }

  return coordinates.toString();
};

const getRoutingURL = (tasks, place) => {
  let location = place?.location?.coordinates;
  const coordinates = [`${location[0]},${location[1]}`];

  tasks.map((task) => {
    const toLocation = task.address?.location?.coordinates;

    if (!isEmpty(toLocation)) {
      coordinates.push(`${toLocation[0]},${toLocation[1]}`);
    }
  });

  if (coordinates.length > 1) {
    return `${ROUTING_API}/${coordinates.join(";")}?alternatives=false`;
  }
  return null;
};

const calculateTasksETA = async (httpClient, tasks, startLocation, startTime) => {
  await httpClient.bareFetcher(getRoutingURL(tasks, startLocation)).then(async (res) => {
    if (res) {
      await scheduleTasks(res, httpClient, tasks, startTime);
    }
  });
};

const DEFAULT_TASK_DURATION = 900; // where 900 represents 15 minutes

const scheduleTasks = async (routes, httpClient, tasks, startTime) => {
  if (routes?.code === "Ok") {
    const legs = routes.routes && routes?.routes[0]?.legs;
    legs.map(async (leg, i) => {
      const start = parseDate(startTime);
      const task = tasks[i];
      let tasksDuration = 0;
      tasks.map((t, j) => {
        if (j < i) {
          tasksDuration = tasksDuration + parseDurationAsSeconds(t?.duration?.toString()) || DEFAULT_TASK_DURATION;
        }
      });
      let eta =
        routes?.routes[0]?.legs
          .slice(0, i + 1)
          .map((o) => o.duration)
          .reduce((a, c) => {
            return a + c;
          }) + tasksDuration;

      // patch tasks
      await httpClient
        .patch(`/tasks/${task.id}/`, {
          scheduled_time: start.add(eta, "second").toISOString(),
        })
        .then((res) => { })
        .catch((e) => {
          console.error(`cannot patch task ${task.id}`, e);
        });
    });
  }
};

const onHandleCloneTask = (httpClient, mutate, task, metaFields: MetaField[]) => {
  const cloneableFields = metaFields
    .filter((metafield) => metafield.is_cloned)
    .map((metafield) => `metafields.${metafield.field_name}`);

  const pickedTask = pick(task, [...cloneProperties, ...cloneableFields]);

  httpClient
    .post("/tasks/", pickedTask)
    .then((res) => {
      if (res.status === httpStatusCodes.CREATED) {
        mutate([res.data]);
        message.success(t`Task cloned successfully`);
      }
    })
    .catch(() => {
      message.error(t`Task clone failed`);
    });
};

const handleMobileReorder = async (
  taskCommands,
  setSelectedTasks,
  featureFlagPrioritizePickup,
  positionFirst,
  taskGroups,
  selectedTasks,
  data
) => {
  const taskKey = data.dragKey;
  const fromKey = data.fromKey;
  const toKey = data.toKey;
  let dropPosition = data.dropPos;
  let dragPosition = data.dragPos;
  let tasks = [...selectedTasks];
  const defaultCreateTaskPos = positionFirst;
  const isSelectedPresent = tasks.find((task) => task.id === taskKey);

  const group = taskGroups.find((group) => group.assignee.id === toKey);
  if (!group) {
    return;
  }

  if (fromKey === toKey && dropPosition > dragPosition) {
    dropPosition = dropPosition + 1;
  }
  // Force moving tasks with same order id in groups outside common assignee
  await Promise.all(
    tasks.map((selectedTask) => {
      taskGroups.map((groups) => {
        groups.tasks.map((task) => {
          const selectedTaskOrderUrl = selectedTask.order?.url || selectedTask.order;
          const taskOrderUrl = task.order?.url || task.order;

          if (selectedTaskOrderUrl && selectedTaskOrderUrl === taskOrderUrl && fromKey !== toKey) {
            if (!tasks.map((task) => task.id).includes(task.id)) {
              tasks.push(task);
            }
          }
        });
      });
    })
  );

  tasks = tasks.sort((a, b) => {
    return a.position - b.position;
  });

  // prioritize pick_up before drop_off  tasks when moving related tasks
  if (featureFlagPrioritizePickup) {
    tasks = await prioritizePickUpTasks(tasks, taskGroups, undefined, dropPosition);
  }
  if (!isSelectedPresent) {
    const draggedTaskGroup = taskGroups.find((g) => extractUuidFromUrl(g.assignee.user) === fromKey);
    if (!draggedTaskGroup) {
      return;
    }
    const task = draggedTaskGroup.tasks.find((t) => t.id === taskKey);

    tasks.unshift(task);
    setSelectedTasks([...tasks]);
  }

  // handle multiple selected task
  const commands = onReorderAndAssignTasks(defaultCreateTaskPos, false, dropPosition, group, tasks, null);

  taskCommands({
    type: "SEND",
    data: commands,
  });
};

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reverse
 The reverse() method in JavaScript reverses an array in place and returns the reference to the same array
 The reverseCoordinates function ensures the original coordinates is not reversed.
 */
const reverseCoordinates = (v: [number, number]) => {
  if (v) {
    return [...v].reverse();
  }

  return null;
};

// removes tasks with routes that does not exist in `routes` object
const filterTasksWithNonExistingRoutes = (enableFilter: boolean, tasks: Task[], routes: { [key: string]: Route }) => {
  if (enableFilter === false) {
    return tasks;
  }

  return tasks.filter((task) => {
    return task.route ? !isEmpty(routes[task.route]) : true;
  });
};

export {
  cloneProperties,
  getAccountRoleByUser,
  getStateColor,
  getTaskCategoryIcon,
  insertTask,
  isTaskCompletedOrEmpty,
  isOffline,
  mergeTasks,
  parseTemplate,
  calculateTasksETA,
  taskStateObject,
  DEFAULT_TASK_DURATION,
  renderCoordinates,
  onHandleCloneTask,
  handleMobileReorder,
  reverseCoordinates,
  unknownTaskStateColor,
  filterTasksWithNonExistingRoutes,
};
