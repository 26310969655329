import * as React from "react";

const ActiveIcon = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill={props.fill} />
      <path
        d="M10 9.69209C10 8.10384 11.7622 7.14926 13.0925 8.01687L19.698 12.3248C20.9079 13.1139 20.9079 14.8861 19.698 15.6752L13.0925 19.9831C11.7622 20.8507 10 19.8962 10 18.3079V9.69209Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ActiveIcon;
