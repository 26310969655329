import { Wrapper } from "@googlemaps/react-wrapper";

import PlacesAutoComplete from "components/map/autocomplete";
import { useGooglePlacesAPI } from "components/map/autocomplete-service/google";
import MapContextMenu from "components/map/context-menu";
import Directions from "components/map/directions";
import Marker from "components/map/marker";
import Polygon from "components/map/polygon";
import Polyline from "components/map/polyline";
import TrafficLayer from "components/map/traffic-layer";
import GoogleMapsWrapper, { render } from "components/map/wrapper";
import { GOOGLE_MAPS_API_KEY } from "contexts/app";

export const defaultLatLng = { lat: 59.43696, lng: 24.75353 };
export const googleMapsScriptId = "googleMapsScript";

export const MapsWrapper = ({ children, ...props }) => {
  return (
    <Wrapper
      apiKey={GOOGLE_MAPS_API_KEY}
      libraries={["places"]}
      id={googleMapsScriptId}
      callback={(e) => {
        props.callback(e);
      }}
      render={render}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

export { PlacesAutoComplete, Directions, MapContextMenu, Marker, Polygon, Polyline, TrafficLayer, useGooglePlacesAPI };

export default GoogleMapsWrapper;
