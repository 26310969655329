import React from "react";

import { t, Trans } from "@lingui/macro";
import { Form, Input, Modal, Row, Select, Typography } from "antd";
import { isEmpty } from "lodash";
import hash from "object-hash";

import Button from "components/uikit/button";
import { useAppContext } from "contexts/app";
import { TASK_COMMAND_ACTIONS } from "types/type";
import { parseDate } from "utils/datetime-utils";
import { getTaskStateFromCommand, mergeTasks } from "utils/tasks";

const taskStates = (disabled) => {
  return [
    {
      value: TASK_COMMAND_ACTIONS.UNASSIGN,
      label: t`Unassign`,
      disabled: disabled,
    },
    {
      value: TASK_COMMAND_ACTIONS.ACCEPT,
      label: t`Accept`,
      disabled: disabled,
    },
    {
      value: TASK_COMMAND_ACTIONS.UNACCEPT,
      label: t`Unaccept`,
      disabled: disabled,
    },
    {
      value: TASK_COMMAND_ACTIONS.TRANSIT,
      label: t`Transit`,
      disabled: disabled,
    },
    {
      value: TASK_COMMAND_ACTIONS.ACTIVATE,
      label: t`Activate`,
      disabled: disabled,
    },
    {
      value: TASK_COMMAND_ACTIONS.COMPLETE,
      label: t`Complete`,
      disabled: disabled,
    },
    {
      value: TASK_COMMAND_ACTIONS.FAIL,
      label: t`Fail`,
      disabled: disabled,
    },
    {
      value: TASK_COMMAND_ACTIONS.CANCEL,
      label: t`Cancel`,
    },
  ];
};

export const handleStateChange = (isFirst, tasks, taskGroups, taskCommands, action, onOpen = undefined, notes = "") => {
  const time = parseDate().toISOString();
  const overwrittenTasks = [];

  if (action === TASK_COMMAND_ACTIONS.UNASSIGN) {
    const group = taskGroups.find((g) => g.assignee.id === getTaskStateFromCommand(TASK_COMMAND_ACTIONS.UNASSIGN));
    tasks = mergeTasks([...tasks], [...group.tasks], isFirst ? 0 : group.tasks.length);
    tasks.forEach((task) => {
      task.assignee = null;
      return task;
    });
  }

  if ([TASK_COMMAND_ACTIONS.TRANSIT, TASK_COMMAND_ACTIONS.ACTIVATE].includes(action)) {
    const group = taskGroups.find((g) => g.assignee.user === tasks[0].assignee);
    tasks = mergeTasks([...tasks], [...group.tasks], 0);
  }

  tasks.map((task) => {
    overwrittenTasks.push({ ...task, state: getTaskStateFromCommand(action) });
  });

  const commands = [];
  tasks.map((task) => {
    if (!isEmpty(task?.url)) {
      const cmd = {
        action: action,
        notes: notes,
        task: task.url,
        time: time,
        external_id: hash({ time, task: task.url, action, notes }),
      };

      if (action === TASK_COMMAND_ACTIONS.UNASSIGN) {
        cmd["task_data"] = { position: task.position };
      }

      commands.push({
        title: t`Change task state`,
        id: hash(cmd),
        payload: cmd,
        tasks: overwrittenTasks,
      });
    } else {
      console.error("ERROR: invalid task", task);
    }
  });

  taskCommands({
    type: "SEND",
    data: commands,
  });
  onOpen && onOpen();
};

const ChangeState = ({ tasks, taskGroups, onOpen, taskCommands }) => {
  const { configuration } = useAppContext();
  const [form] = Form.useForm();

  const pos = !!configuration?.features?.task_create_position_first;
  const isDisabled = tasks.some((task) => !task.assignee);

  return (
    <Modal
      open={true}
      onCancel={onOpen}
      title={
        <Typography.Title level={4}>
          <Trans>Change Task state</Trans>
        </Typography.Title>
      }
      footer={null}
    >
      <Form
        form={form}
        onFinish={(values) => {
          handleStateChange(pos, tasks, taskGroups, taskCommands, values.action, onOpen, values.notes);
        }}
      >
        <Form.Item
          name={"action"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={taskStates(isDisabled)} placeholder={t`Choose state`} />
        </Form.Item>

        <Form.Item name={"notes"}>
          <Input.TextArea placeholder={t`Notes`} rows={5} />
        </Form.Item>

        <Row justify="space-between">
          <Button type={"default"} htmlType={"reset"} onClick={onOpen} label={t`Cancel`} />
          <Button type={"primary"} htmlType={"submit"} label={t`Change State`} />
        </Row>
      </Form>
    </Modal>
  );
};

export default ChangeState;
