export const darkMode: any = [
  {
    stylers: [
      {
        hue: "#ff1a00",
      },
      {
        invert_lightness: true,
      },
      {
        saturation: -100,
      },
      {
        lightness: 33,
      },
      {
        gamma: 0.5,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#2D333C",
      },
    ],
  },
];

export const reverseGeocode = async (lat: number, lng: number) => {
  const geocoder = new google.maps.Geocoder();
  const request = {
    location: {
      lat,
      lng,
    },
  };

  return await geocoder.geocode(request, (result, status) => {
    if (status === "OK") {
      return { result };
    } else {
      return { result: [{ formatted_address: "Unknown address" }] };
    }
  });
};

export const getPlaceDetails = async (placeId: string) => {
  const geocoder = new google.maps.Geocoder();
  const request = {
    placeId: placeId,
  };

  return await geocoder.geocode(request, (result, status) => {
    if (status === "OK") {
      return { result };
    } else {
      return { result: [{ formatted_address: "Unknown address" }] };
    }
  });
};

export const setLatLngPrecision = (latLng) => {
  const r = 0.01 / 111300; // = 20 meters around the specified location
  const y0 = latLng.lat;
  const x0 = latLng.lng;
  const w = r * Math.sqrt(Math.random());
  const t = 2 * Math.PI * Math.random();
  const x = w * Math.cos(t);
  const y1 = w * Math.sin(t);
  const x1 = x / Math.cos(y0);

  return { lat: y0 + y1, lng: x0 + x1, zoom: 16 };
};
