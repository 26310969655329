import { t } from "@lingui/macro";
import { isEmpty } from "lodash";

import { setLatLngPrecision } from "components/map/utils";

const workerActions = (
  isMarker,
  gtasks,
  dispatchActions,
  onCenterWorker,
  setBounds,
  setIsConnecting,
  isCalendar,
  opened,
  enableTasksRoutes,
  isManager
): any => {
  const isUnassigned = gtasks?.assignee?.id === "unassigned";

  return [
    {
      ...(isMarker && {
        key: 0,
        label: t`Connect`,
        action: () => {
          if (setIsConnecting) {
            const coord = gtasks.assignee.last_location.location.coordinates;

            setIsConnecting({ type: "ACTIVE" });
            setIsConnecting({
              type: "SOURCE",
              value: {
                coordinates: { lat: coord[1], lng: coord[0] },
                workerId: gtasks.assignee.user,
              },
            });
          }
        },
      }),
    },
    {
      key: 1,
      label: t`Center in map`,
      action: () => {
        if (onCenterWorker) {
          const workerLocation = gtasks?.assignee?.last_location?.location;
          const coordinates = [];
          if (workerLocation) {
            const workerCoordinates = {
              lat: workerLocation.coordinates[1],
              lng: workerLocation.coordinates[0],
            };
            onCenterWorker(setLatLngPrecision(workerCoordinates));
            coordinates.push(workerCoordinates);
            if (opened.includes(gtasks["assignee"]["id"])) {
              gtasks.tasks.map((task) => {
                const coordinate = task?.address?.location?.coordinates;
                if (!isEmpty(coordinate)) {
                  coordinates.push({
                    lat: coordinate[1],
                    lng: coordinate[0],
                  });
                }
              });
            }
            if (coordinates) {
              setBounds([...coordinates]);
            }
          }
        }
      },
    },
    {
      key: 2,
      label: t`Optimize worker route`,
      action: () => {
        dispatchActions({ type: "OPTIMIZE", routeId: null });
        dispatchActions({ type: "TASKS", value: gtasks });
      },
    },
    {
      key: 3,
      label: t`Re-assign workers tasks`,
      action: () => {
        dispatchActions({ type: "CHANGE_ASSIGNEE" });
        dispatchActions({ type: "TASKS", value: gtasks.tasks });
      },
    },
    {
      key: 4,
      label: t`Change state of workers tasks`,
      action: () => {
        dispatchActions({ type: "CHANGE_STATE" });
        dispatchActions({ type: "TASKS", value: gtasks.tasks });
      },
    },
    {
      ...(enableTasksRoutes && {
        key: 5,
        label: t`Create route`,
        disabled: !isManager,
        action: () => {
          dispatchActions({ type: "OPEN_ROUTE_PROMPT", routeId: null, assignee: gtasks.assignee.user });
        },
      }),
    },
    {
      ...(!isUnassigned &&
        isCalendar && {
          key: 6,
          label: t`Schedule tasks`,
          action: () => {
            dispatchActions({ type: "SCHEDULE_TASK" });
            dispatchActions({ type: "TASKS", value: gtasks.tasks });
          },
        }),
    },
  ];
};

export const getWorkerContextMenu = (
  isMarker,
  group,
  dispatchActions,
  onCenterWorker,
  setBounds,
  isManager,
  setIsConnecting = null,
  isCalendar = false,
  opened = [],
  enableTasksRoutes = false
) => {
  return workerActions(
    isMarker,
    group,
    dispatchActions,
    onCenterWorker,
    setBounds,
    setIsConnecting,
    isCalendar,
    opened,
    enableTasksRoutes,
    isManager
  );
};
