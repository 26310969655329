import React from "react";

import { ClockCircleOutlined, WarningTwoTone } from "@ant-design/icons";
import { Space, Tooltip } from "antd";
import { get, isEmpty, startCase } from "lodash";
import styled from "styled-components";

import useTaskContextMenu from "components/dashboard/contextmenu/task";
import { ContextMenuButton } from "components/dashboard/tasks/utils";
import { keyboardMetaKey } from "components/dashboard/utils";
import ContextMenu from "components/menus/context-menu";
import { useAppContext } from "contexts/app";
import CheckedTaskIcon from "icons/checked-task";
import MoveArrows from "icons/move-arrows";
import UnCheckedTaskIcon from "icons/unchecked-task";
import { getTaskMarker } from "utils/markers-utils";
import { parseTemplate, renderCoordinates, sameDayFormat } from "utils/tasks";

const Container = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 3px 8px;
  border-radius: 3px;

  background: ${(props: { selected: boolean; isHighlighted: boolean; grouped: boolean }) =>
    props.selected && "#4B515B"} !important;
  background: ${(props: { selected: boolean; isHighlighted: boolean; grouped: boolean }) =>
    props.isHighlighted && "#4B515B"} !important;

  .marker {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 35px;
    margin-right: 8px;
  }

  width: ${(props: { selected: boolean; isHighlighted: boolean; grouped: boolean }) =>
    props.grouped && "100%"} !important;
`;

const Address = styled.div<{ mobileView: boolean; grouped: boolean }>`
  display: inline-block;
  color: #ffffff !important;
  line-height: 1.2em;
  max-width: 100%;

  div {
    white-space: nowrap;
    font-size: 11px;
    width: 0;

    .date {
      -webkit-text-fill-color: #84858e;
      color: #84858e !important;
      font-size: 11px;
      letter-spacing: 0.5px;
    }
  }
`;
export const TaskMarker = styled.img`
  margin-top: -8px;
  height: 33px;
`;

const Marker = styled.span`
  width: 36px;
`;

const ContextContainer = styled.div`
  z-index: 1;
  position: absolute;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    position: absolute;
  }
  right: 6px;
  width: 20px;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 30px;
  }
`;

export const OverLay = styled.div<{ overlay: string }>`
  width: 50%;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 70%;
  }
  height: 100%;
  background-image: ${(props: { overlay: string }) => props.overlay};
  position: absolute;
  right: 0;
  border-radius: 5px;
`;

export const DropIndicator = styled.div`
  height: 1px;
  width: 100%;
`;

export const TaskDropArea = styled.div`
  height: 50px;
  position: absolute;
  top: 0;
  left: 24px;
  width: 100%;
`;

const TooltipContainer = styled.div`
  width: 0;
  position: relative;
  right: 8px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 10px;
    position: unset;
    right: unset;
  }
`;

const TaskItem = ({
  taskKey,
  clearTasks,
  dispatchActions,
  editTask,
  handleSelect,
  handleMultipleSelect,
  handleCheckBoxSelect,
  handleSingletonSelect,
  index,
  isSelected,
  isHighlighted,
  onMutateGroupedTasks,
  onSetEditAbleTask,
  onSetSelectedGroup,
  onSetSelectedRoute,
  onSetTasksCenter,
  task,
  tasks,
  handleCloneTasks,
  taskCommands,
  taskGroups,
  isGrouped = false,
  setNodeData = undefined,
  onReorder,
  isMobileView,
}) => {
  const { configuration } = useAppContext();
  const ctxMenu = useTaskContextMenu(
    false,
    task,
    tasks,
    clearTasks,
    editTask,
    onSetEditAbleTask,
    onMutateGroupedTasks,
    dispatchActions,
    onSetTasksCenter,
    handleCloneTasks,
    taskCommands,
    taskGroups
  );

  const clickHandler = (e) => {
    e.stopPropagation();
    onSetSelectedGroup();
    onSetSelectedRoute();

    if (e.shiftKey) {
      handleMultipleSelect(task);
      return;
    }

    if (keyboardMetaKey(e)) {
      handleCheckBoxSelect(task);
      return;
    }

    handleSelect(task);
  };

  const tmpl = get(configuration, "templates.dashboard_task");
  const dateFormat = get(configuration, "settings.date_format");
  const timeFormat = get(configuration, "settings.time_format");
  const applyTimeFormat = get(configuration, "features.task_templates_datetime_format") === "short";
  const [line1, line2, ...line3] = parseTemplate({
    tmpl,
    task,
    separator: "\n",
    dateFormat,
    timeFormat,
    applyTimeFormat,
  });

  const after = sameDayFormat(task.complete_after, dateFormat, timeFormat, applyTimeFormat);
  const before = sameDayFormat(task.complete_before, dateFormat, timeFormat, applyTimeFormat, task.complete_after);

  let overlay: string;

  switch (true) {
    case isGrouped && isSelected:
      overlay = "linear-gradient(to right, rgba(75, 81, 91, 0), rgba(75, 81, 91, 0.53), #4B515B 80%)";
      break;
    case isGrouped && !isSelected:
      overlay = "linear-gradient(to right, rgba(59, 64, 73, 0), rgba(59, 64, 73, 0.53), #3B4049 80%)";
      break;
    case !isGrouped && !isSelected:
      overlay = "linear-gradient(to right, rgba(42, 47, 57, 0), rgba(42, 47, 57, 0.53), #2A2F39 80%)";
      break;
    default:
      overlay = "linear-gradient(to right, rgba(75, 81, 91, 0), rgba(75, 81, 91, 0.53), #4B515B 80%)";
  }

  const onContextMenu = () => {
    if (!tasks.map((t) => t.id).includes(task.id)) {
      handleSingletonSelect([task]);
    }
  };

  const onDragOver = () => {
    if (setNodeData) {
      setNodeData(taskKey);
    }

    document
      ?.getElementById(taskKey)
      ?.setAttribute(
        "style",
        "background: linear-gradient(to right, transparent 33%, #ffffff 0%) bottom/10px 1px repeat-x, linear-gradient(to right, transparent 33%, #2a2f39 0%) bottom/10px 1px repeat-x"
      );
  };

  const onDragLeave = () => {
    // this should handle drop events
    document?.getElementById(taskKey)?.setAttribute("style", "background: transparent");
  };
  const onDone = () => {
    onDragLeave();

    if (taskKey) {
      onReorder();
    }
  };

  return (
    <>
      <ContextMenu items={ctxMenu} rowData={task}>
        <Container
          onClick={(e) => clickHandler(e)}
          selected={isSelected}
          isHighlighted={isHighlighted}
          grouped={isGrouped}
          onDoubleClick={() => {
            dispatchActions({ type: "TASK", value: task });
            dispatchActions({ type: "TASK_INFO" });
          }}
          onContextMenu={onContextMenu}
          className={"task-item"}
        >
          <Marker
            className={"marker"}
            onClick={(e) => {
              e.stopPropagation();

              handleCheckBoxSelect(task);
            }}
          >
            {tasks.length > 0 ? (
              <>{isSelected ? <CheckedTaskIcon /> : <UnCheckedTaskIcon />}</>
            ) : (
              <MoveArrows style={{ width: 12 }} />
            )}
            <TaskMarker src={getTaskMarker(task.state, index, false)} alt="task-marker" />
          </Marker>
          <Address mobileView={isMobileView} grouped={isGrouped}>
            <div>
              {(!isEmpty(task?.coordinates) && renderCoordinates(task?.coordinates)) ||
                line1 ||
                task.address.formatted_address}
              <br />
              <span className={"date"}>
                <ClockCircleOutlined /> {after} - {before} {line2 ? line2.toString() : ""}
                <br />
                {line3 &&
                  line3.map((line, i) => {
                    return <React.Fragment key={i}>{line.toString()}</React.Fragment>;
                  })}
              </span>
            </div>
          </Address>
          <OverLay overlay={overlay} />
          <ContextContainer>
            <Space>
              <TooltipContainer>
                {task?.issues?.length > 0 && (
                  <Tooltip title={startCase(task.issues.toString())} placement={"left"}>
                    <WarningTwoTone twoToneColor={"red"} />
                  </Tooltip>
                )}
              </TooltipContainer>
              <ContextMenuButton items={ctxMenu} rowData={task} onClick={onContextMenu} />
            </Space>
          </ContextContainer>
          <TaskDropArea onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDone} />
        </Container>
      </ContextMenu>
    </>
  );
};

const DummyTaskContainer = styled.div`
  height: 10px;
`;

const DummyTask = ({
  setNodeData,
  taskKey,
  onReorder,
}: {
  taskKey: string;
  setNodeData: (e: any) => void;
  onReorder: () => void;
}) => {
  const onDragOver = () => {
    if (setNodeData) {
      setNodeData(taskKey);
    }

    document
      .getElementById(taskKey)
      ?.setAttribute(
        "style",
        "background: linear-gradient(to right, transparent 33%, #ffffff 0%) bottom/10px 1px repeat-x, linear-gradient(to right, transparent 33%, #2a2f39 0%) bottom/10px 1px repeat-x"
      );
  };

  const onDragLeave = () => {
    // this should handle drop events
    document.getElementById(taskKey)?.setAttribute("style", "background: transparent");
  };
  const onDone = () => {
    onDragLeave();

    if (taskKey) {
      onReorder();
    }
  };

  return (
    <DummyTaskContainer onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDone}>
      <DropIndicator id={taskKey} />
    </DummyTaskContainer>
  );
};

export { DummyTask };
export default TaskItem;
