import React, { ReactElement, ReactNode } from "react";

import { Button as AntdButton } from "antd";
import { BaseButtonProps, ButtonHTMLType } from "antd/lib/button/button";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import styled from "styled-components";

export interface ButtonProps {
  type: "link" | "text" | "default" | "ghost" | "primary" | "dashed";
  label?: ReactNode;
  block?: boolean;
  ghost?: boolean;
  htmlType?: ButtonHTMLType;
  antdProps?: BaseButtonProps;
  width?: number;
  height?: number;
  danger?: boolean;
  showTime?: boolean;
  loading?: boolean;
  size?: SizeType;
  disabled?: boolean;
  icon?: ReactElement;
  onClick?: () => void;
  outline?: boolean;
  style?: any;
  strong?: boolean;
}

const StyledButton = styled(AntdButton)<ButtonProps>`
  ${(props) => `
      border:  ${props?.type === "ghost" ? "1px solid #60FA67" : "none"};
      :hover, :focus {
        color: ${props?.type === "link" ? "#9A39FB" : "#000000"};
      }
`}
`;

const Button = ({ ...props }: ButtonProps) => {
  return <StyledButton {...props}>{props.label}</StyledButton>;
};

export default Button;
