import { MutableRefObject, ReactElement, ReactNode } from "react";

import { Status } from "@googlemaps/react-wrapper";
import { Result } from "antd";

import PageLoader from "components/loading";
import Map from "components/map/map";
import GSMTasksButton from "components/uikit/button";
import { t } from "@lingui/macro";

interface GoogleMapsWrapperProps {
  autoZoom?: boolean;
  aspectRatio?: (v) => void;
  setBounds?: (v) => void;
  urlBounds?: MutableRefObject<string>;
  bounds?: google.maps.LatLngLiteral[];
  center?: google.maps.LatLngLiteral;
  children: ReactNode;
  isDarkMode?: boolean;
  maxZoom?: number;
  onClick?: (e: any) => void;
  onRightClick?: (location: any) => void;
  onLongPress?: (e: any) => void;
  onBoundsChange?: (bounds: google.maps.LatLngBounds) => void;
  onMouseMove?: (e: any) => void;
  options?: any;
  loadMapCallback?: () => void;
  setMapAreaRef?: any;
  zoom?: number;
  height: string;
}

const GoogleMapsWrapper = ({
  autoZoom,
  aspectRatio,
  setBounds,
  urlBounds,
  bounds,
  center,
  children,
  isDarkMode,
  maxZoom,
  onClick,
  onRightClick,
  onLongPress,
  onMouseMove,
  onBoundsChange,
  options,
  setMapAreaRef,
  zoom,
  height,
}: GoogleMapsWrapperProps) => {
  return (
    <Map
      setMapAreaRef={setMapAreaRef}
      autoZoom={autoZoom}
      aspectRatio={aspectRatio}
      setBounds={setBounds}
      urlBounds={urlBounds}
      options={options}
      bounds={bounds}
      center={center}
      isDarkMode={isDarkMode}
      maxZoom={maxZoom}
      onClick={onClick}
      onRightClick={onRightClick}
      onBoundsChange={onBoundsChange}
      onMouseMove={onMouseMove}
      onLongPress={onLongPress}
      zoom={zoom}
      height={height}
    >
      {children}
    </Map>
  );
};

export const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <PageLoader />;
  if (status === Status.FAILURE)
    return (
      <Result
        status="error"
        title={t`An issue occurred while trying to setup maps.`}
        extra={
          <GSMTasksButton
            type="primary"
            key="reload-btn"
            label={"Reload page"}
            onClick={() => {
              window.location.reload();
            }}
          />
        }
      />
    );
  return <></>;
};

export default GoogleMapsWrapper;
