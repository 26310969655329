import React from "react";

import { t } from "@lingui/macro";
import { Select, Space } from "antd";
import Image from "next/image";

import { getWorkerMarker } from "utils/markers-utils";
import { emptyState } from "utils/notifications-utils";
import { isOffline } from "utils/tasks";

const workerPrefix = "worker:";
const addressPrefix = "address:";
const contactPrefix = "contact:";
const tasksPrefix = "tasks:";

const renderAccountRoles = (accounts) => (
  <Select.OptGroup label={t`Workers`} key={"workers"}>
    {accounts.map((account) => {
      const expandableIcon = getWorkerMarker(
        account?.is_on_duty,
        account?.is_on_duty && isOffline(account?.last_location?.updated_at, 15, "minute"),
        90
      );
      return (
        <Select.Option
          key={workerPrefix + account?.id}
          value={account?.id}
          disabled={!account?.last_location?.location}
        >
          <Space>
            <Image src={expandableIcon} alt="expandable-icon" width={14} height={14} />
            <span>
              {account?.display_name || account?.email} {!account?.last_location?.location && t`(cannot locate worker)`}
            </span>
          </Space>
        </Select.Option>
      );
    })}
    {accounts.length === 0 && emptyState(1)}
  </Select.OptGroup>
);

const renderAddresses = (predictions) => (
  <Select.OptGroup label={t`Address`} key={"address"}>
    {predictions &&
      predictions.map((places, placesIndex) => {
        return (
          <Select.Option key={addressPrefix + placesIndex} value={placesIndex} places={places}>
            {places?.properties?.formatted_address}
          </Select.Option>
        );
      })}
    {!predictions && emptyState(2)}
  </Select.OptGroup>
);

const renderContactAddresses = (contacts) => (
  <Select.OptGroup label={t`Contact Address`} key={"contacts"}>
    {contacts &&
      contacts
        .filter((c) => c?.address?.location?.coordinates)
        .map((contact) => {
          return (
            <Select.Option key={contactPrefix + contact?.id} value={contactPrefix + contact?.id} contact={contact}>
              {contact.contact.name || "-"}
              <br />
              {contact.contact.company || "-"}
              <br />
              {contact.address.formatted_address || "-"}
            </Select.Option>
          );
        })}
    {!contacts && emptyState(3)}
  </Select.OptGroup>
);

const renderTasks = (tasks) => (
  <Select.OptGroup label={t`Tasks`} key={"tasks"}>
    {tasks &&
      tasks
        .filter((t) => t?.address?.location?.coordinates)
        .map((task) => {
          return (
            <Select.Option key={tasksPrefix + task.id} task={task} assigneeId={task.assigneeId}>
              {task.address.formatted_address || "-"}
              <br />
              {task.contact.name || "-"} {task.contact.name && task.contact.company && "|"} {task.contact.company}
            </Select.Option>
          );
        })}
    {!tasks && emptyState(4)}
  </Select.OptGroup>
);

export {
  workerPrefix,
  addressPrefix,
  contactPrefix,
  tasksPrefix,
  renderTasks,
  renderContactAddresses,
  renderAddresses,
  renderAccountRoles,
};
