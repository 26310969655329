import * as React from "react";

const UpdatedIcon = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6755 20.6665H20.6843"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1799 7.81735C17.4897 7.50754 17.9099 7.3335 18.348 7.3335C18.565 7.3335 18.7798 7.37623 18.9802 7.45925C19.1806 7.54227 19.3627 7.66395 19.5161 7.81735C19.6695 7.97075 19.7912 8.15287 19.8742 8.3533C19.9573 8.55373 20 8.76854 20 8.98549C20 9.20243 19.9573 9.41725 19.8742 9.61768C19.7912 9.81811 19.6695 10.0002 19.5161 10.1536L9.78169 19.8881L6.66667 20.6668L7.44542 17.5518L17.1799 7.81735Z"
        stroke="#949494"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#ECECEC" />
    </svg>
  );
};

export default UpdatedIcon;
