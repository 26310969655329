import { useEffect } from "react";

import * as Sentry from "@sentry/nextjs";
import { Configuration } from "types/type";

const useInitSentryUser = (configuration?: Configuration) => {
  useEffect(() => {
    if (configuration?.user?.id) {
      Sentry.setUser({
        email: configuration?.user?.email,
        username: configuration?.user?.display_name,
        id: configuration?.user?.id,
        account: configuration?.account?.name,
        account_id: configuration?.account?.id,
      });
    }

    return () => {
      Sentry.setUser(null);
    };
  }, [configuration]);
};

export default useInitSentryUser;
