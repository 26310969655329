import { useEffect, useState } from "react";

import useDeepCompareEffect from "use-deep-compare-effect";
import { isEmpty } from "lodash";

const Polyline = (options) => {
  const [polyline, setPolyline] = useState<google.maps.Polyline>();

  useEffect(() => {
    if (!polyline) {
      setPolyline(new google.maps.Polyline());
    }

    return () => {
      if (polyline) {
        polyline.setMap(null);
      }
    };
  }, [polyline]);

  useDeepCompareEffect(() => {
    if (isEmpty(options?.options?.path)) {
      return;
    }

    if (polyline) {
      polyline.setOptions(options);
      polyline.addListener("dblclick", (e) => {
        polyline.setMap(null);
      });
    }
  }, [polyline, options]);

  return null;
};

export default Polyline;
