import React, { ReactNode, useState } from "react";

import { t } from "@lingui/macro";
import { AutoComplete, Row, Select, Typography } from "antd";
import styled from "styled-components";
import useSWR from "swr";
import useDeepCompareEffect from "use-deep-compare-effect";

import { useAutoCompleteService } from "components/map/autocomplete-service";
import { useAppContext } from "contexts/app";
import { useDebounce } from "hooks/debounce";
import { emptyState } from "utils/notifications-utils";
import { renderContactAddresses } from "utils/tasks-search-utils";
import { LoadingOutlined } from "@ant-design/icons";

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
`;

interface AutoCompleteProps {
  values?: string;
  label: string;
  disabled?: boolean;
  options?: google.maps.places.AutocompleteOptions;
  onChange: (v) => void;
  children: ReactNode;
  shouldClearInput?: boolean;
  shouldSelect?: boolean;
  showContactsOptions?: boolean;
  placeholder?: string;
  reverse?: boolean;
  onClear?: () => void;
}

const PlacesAutoComplete = ({
  label,
  onChange,
  children,
  reverse,
  shouldSelect,
  onClear,
  ...props
}: AutoCompleteProps) => {
  const { appData } = useAppContext();

  const [params, setParams] = useState<string>();

  useDeepCompareEffect(() => {
    if (props.values) {
      setParams(props.values);
    }
  }, [{ v: props.values }]);

  const { isLoading, suggestions, setPlaceCallback } = useAutoCompleteService(params);

  const debouncedParams = useDebounce(params, 500);
  const { data: contactAddress } = useSWR(
    props.showContactsOptions &&
      debouncedParams &&
      `/contact_addresses/?account=${appData.accountId}&search=${debouncedParams}&page_size=5`
  );

  return (
    <div>
      {label && <label>{label}</label>}
      <StyledAutoComplete
        onSelect={(_, option) => {
          if (option.contact) {
            onChange(option.contact);
            if (props?.shouldClearInput) {
              setParams("");
            }
            return;
          }
          setPlaceCallback(option, props, onChange, setParams, reverse);
        }}
        onSearch={(e: string) => {
          setParams(e);
          if (shouldSelect === false) {
            onChange({ raw_address: e, formatted_address: e });
          }
        }}
        disabled={props?.disabled}
        placeholder={props?.placeholder}
        value={params}
        allowClear
        onClear={onClear}
      >
        <Select.OptGroup
          label={
            <Row justify={"space-between"}>
              <Typography.Text>{t`Address`}</Typography.Text> {isLoading && <LoadingOutlined spin={true} />}
            </Row>
          }
          key={"places-address"}
        >
          {suggestions &&
            suggestions.map((places, placesIndex: number) => {
              return (
                <Select.Option key={placesIndex} value={placesIndex} places={places}>
                  {places?.properties?.formatted_address}
                </Select.Option>
              );
            })}
          {!suggestions && emptyState(7)}
        </Select.OptGroup>
        {props.showContactsOptions && renderContactAddresses(contactAddress)}
      </StyledAutoComplete>
    </div>
  );
};

export default PlacesAutoComplete;
