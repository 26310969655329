const MoveArrows = ({ ...props }) => {
  return (
    <svg width="6" height="17" viewBox="0 0 6 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.85289 6.49825V3.38918H0.142855C0.085082 3.38918 0.0330286 3.35162 0.0110061 3.29436C-0.0113025 3.23679 0.000995822 3.17091 0.0416089 3.12658L2.89453 0.0452548C2.92113 0.0163163 2.95774 0 2.99578 0C3.03382 0 3.07014 0.0163163 3.09703 0.0452548L5.95824 3.12627C5.98541 3.15583 6 3.19523 6 3.23525C6 3.25526 5.99628 3.27497 5.98913 3.29405C5.96711 3.35162 5.91477 3.38918 5.857 3.38918H4.14095V6.49825C4.14095 6.58321 4.07689 6.65217 3.99795 6.65217H1.9959C1.91696 6.65217 1.85289 6.49825 1.85289 6.49825Z"
        fill="#949494"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14711 13.6108H5.85714C5.91492 13.6108 5.96697 13.6484 5.98899 13.7056C6.0113 13.7632 5.999 13.8291 5.95839 13.8734L3.10547 16.9547C3.07887 16.9837 3.04226 17 3.00422 17C2.96618 17 2.92986 16.9837 2.90297 16.9547L0.0417571 13.8737C0.0145864 13.8442 0 13.8048 0 13.7647C0 13.7447 0.0037179 13.725 0.0108681 13.7059C0.0328908 13.6484 0.0852299 13.6108 0.143003 13.6108H1.85905L1.85905 10.5018C1.85905 10.4168 1.92311 10.3478 2.00205 10.3478H4.0041C4.08304 10.3478 4.14711 10.4168 4.14711 10.5018L4.14711 13.6108Z"
        fill="#949494"
      />
    </svg>
  );
};

export default MoveArrows;
