import { useEffect } from "react";

import { i18n } from "@lingui/core";
import { ConfigProvider, message } from "antd";
import de_DE from "antd/lib/locale-provider/de_DE";
import en_GB from "antd/lib/locale-provider/en_GB";
import es_ES from "antd/lib/locale-provider/es_ES";
import et_EE from "antd/lib/locale-provider/et_EE";
import fi_FI from "antd/lib/locale-provider/fi_FI";
import lt_LT from "antd/lib/locale-provider/lt_LT";
import lv_LV from "antd/lib/locale-provider/lv_LV";
import pl_PL from "antd/lib/locale-provider/pl_PL";
import ru_RU from "antd/lib/locale-provider/ru_RU";
import moment from "moment";

import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/et";
import "moment/locale/fi";
import "moment/locale/lt";
import "moment/locale/lv";
import "moment/locale/pl";
import "moment/locale/ru";

import { initTimeLocale } from "utils/datetime-utils";

const locales = {
  en: en_GB,
  et: et_EE,
  de: de_DE,
  es: es_ES,
  ru: ru_RU,
  lt: lt_LT,
  lv: lv_LV,
  fi: fi_FI,
  pl: pl_PL,
};

const AntdApplicationConfig = ({ children, language }) => {
  // Locale
  useEffect(() => {
    // global message toast configuration
    message.config({
      duration: 3,
      maxCount: 1,
    });

    async function load(locale) {
      const { messages } = await import(`/locale/${locale}/messages.po`);

      i18n.load(locale, messages);
      i18n.activate(locale);

      // import & globally set time-format based on locale
      await initTimeLocale(locale);
      moment.locale(locale);
    }

    load(language || "en");
  }, [language]);

  return <ConfigProvider locale={locales[language] || en_GB}>{children} </ConfigProvider>;
};

export default AntdApplicationConfig;
