import { FC, useEffect, useState } from "react";

const TrafficLayer: FC<google.maps.TrafficLayerOptions> = (options) => {
  const [trafficLayer, setTrafficLayer] = useState<google.maps.TrafficLayer>();

  useEffect(() => {
    if (!trafficLayer) {
      setTrafficLayer(new google.maps.TrafficLayer());
    }

    return () => {
      if (trafficLayer) {
        trafficLayer.setMap(null);
      }
    };
  }, [trafficLayer]);

  useEffect(() => {
    if (trafficLayer) {
      trafficLayer.setOptions(options);
    }
  }, [trafficLayer, options]);

  return null;
};

export default TrafficLayer;
