import { Dispatch, useEffect, useState } from "react";

import useDeepCompareEffect from "use-deep-compare-effect";

const Directions = ({
  points,
  onSummarize,
  ...props
}: {
  points: { coordinate: google.maps.LatLngLiteral }[];
  onSummarize: Dispatch<any>;
  map?: google.maps.Map;
}) => {
  const [render, setRender] = useState<google.maps.DirectionsRenderer>();
  const [service, setService] = useState<google.maps.DirectionsService>(); //TODO: use gsmtasks service because of costs implications

  useDeepCompareEffect(() => {
    if (!render) {
      setRender(new google.maps.DirectionsRenderer());
      setService(new google.maps.DirectionsService());
    }

    return () => {
      if (render) {
        render.setMap(null);
      }
    };
  }, [props, render, service]);

  useEffect(() => {
    if (render) {
      render.setOptions({ map: props.map, preserveViewport: true });
    }
  }, [props.map, render]);

  useDeepCompareEffect(() => {
    if (points.length > 10) {
      /* points more than 10 has cost implications...
       * https://developers.google.com/maps/documentation/directions/usage-and-billing
       * */
      return;
    }

    if (render && points.length > 1) {
      const wayPoints = [];
      const last = points.length - 1 || 0;
      points.map((point, index) => {
        wayPoints.push({
          location: point.coordinate,
          stopover: true,
        });
      });
      const reqBody = {
        origin: wayPoints[0].location,
        destination: wayPoints[last].location,
        waypoints: wayPoints.length > 2 ? wayPoints.slice(1, -1) : undefined,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      };

      service
        .route(reqBody)
        .then((res) => {
          const summary = [];
          const legs = res.routes[0].legs;
          if (legs.length === 1) {
            legs.map((leg) => {
              summary.push({
                address: leg.start_address,
              });
              summary.push({
                address: leg.end_address,
                distance: leg.distance,
                duration: leg.duration,
              });
            });
          } else {
            legs.map((leg, i) => {
              summary.push({
                address: leg.start_address,
                distance: i === 0 ? undefined : legs[i - 1].distance,
                duration: i === 0 ? undefined : legs[i - 1].duration,
              });
            });
            summary.push({
              address: legs[legs.length - 1].end_address,
              distance: legs[legs.length - 1].distance,
              duration: legs[legs.length - 1].duration,
            });
          }
          // TODO convert to callback so its generic
          if (onSummarize) {
            onSummarize({ type: "UPDATE", value: summary });
          }
          render.setDirections(res);
        })
        .catch((e) => console.error("Directions request failed due to " + e));
    }
  }, [points, render, service]);

  return null;
};

export default Directions;
