import * as React from "react";

const ScheduledIcon = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#FFAB00" />
      <path
        d="M19.52 9.08H17V8.12C17 8.054 16.946 8 16.88 8H16.04C15.974 8 15.92 8.054 15.92 8.12V9.08H12.08V8.12C12.08 8.054 12.026 8 11.96 8H11.12C11.054 8 11 8.054 11 8.12V9.08H8.48C8.2145 9.08 8 9.2945 8 9.56V19.52C8 19.7855 8.2145 20 8.48 20H19.52C19.7855 20 20 19.7855 20 19.52V9.56C20 9.2945 19.7855 9.08 19.52 9.08ZM18.92 18.92H9.08V13.22H18.92V18.92ZM9.08 12.2V10.16H11V10.88C11 10.946 11.054 11 11.12 11H11.96C12.026 11 12.08 10.946 12.08 10.88V10.16H15.92V10.88C15.92 10.946 15.974 11 16.04 11H16.88C16.946 11 17 10.946 17 10.88V10.16H18.92V12.2H9.08Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};

export default ScheduledIcon;
