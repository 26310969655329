import React from "react";

import { Select, Typography } from "antd";
import { Trans } from "@lingui/macro";

const getNotificationStateColor = (state) => {
  switch (state) {
    case "queued":
      return "blue";
    case "sent":
      return "green";
    case "over_quota":
      return "orange";
    case "failed":
      return "red";
    default:
      return null;
  }
};

const emptyState = (key) => (
  <Select.Option key={"not-found" + key} disabled>
    <Typography.Text italic>
      <Trans>No result found</Trans>
    </Typography.Text>
  </Select.Option>
);

export { getNotificationStateColor, emptyState };
